/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, put, takeEvery, call, select, takeLatest } from 'redux-saga/effects'
import {
  addDataInSubclass,
  createAnnotation,
  createVideoAnnotation,
  createVideoSubClass,
  deleteAnnotation,
  deleteVideoAnnotation,
  generateAnvil,
  generateAnvilBackground,
  getAnnotatedVideos,
  getAnnotateInit,
  getAnnotationStaffs,
  getAnvilData,
  getAWSVideoList,
  getClassSubClassInfo,
  getInterrateReport,
  getStudents,
  getVideoById,
  getVideoClass,
  getVideoClip,
  getVideoClipsByClass,
  updateVideo,
  updateVideoSatus,
  getMoreDropdown,
  addMoreData,
  getStudentTargets,
} from 'services/annotations'
import actions from './actions'
import reportAction from '../Reports/actions'

export function* GET_ANNOTATED_VIDEOS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnotatedVideosLoading: true,
    },
  })

  const response = yield call(getAnnotatedVideos, { ...payload })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        annotatedVideos: response.getVideos.edges.map(({ node }) => ({ ...node })),

        videoCount: response.getVideos.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnotatedVideosLoading: false,
    },
  })
}

export function* GET_VIDEO_CLIP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClipLoading: true,
    },
  })

  const response = yield call(getVideoClip, { ...payload })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        videoClips: response.videoClipNew.edges.map(({ node }) => ({ ...node })),
        clipCount: response.videoClipNew.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClipLoading: false,
    },
  })
}

export function* GENERATE_ANVIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnvilGenerating: true,
    },
  })
  const response = yield call(generateAnvil, payload)
  if (response) {
    window.open(response.generateAnvil.url)
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnvilGenerating: false,
    },
  })
}

export function* DELETE_VIDEO_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoDeleting: true,
    },
  })
  const response = yield call(deleteVideoAnnotation, payload)
  if (response && response.deleteVideoAnnotation.status) {
    notification.success({
      message: 'Annotation Video Deleted Successfully!',
    })
    yield put({
      type: actions.POP_VIDEO_ANNOTATION,
      payload: {
        id: payload.pk,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoDeleting: false,
    },
  })
}

export function* CREATE_VIDEO_SUB_CLASS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassSaving: true,
    },
  })
  const response = yield call(createVideoSubClass, payload)
  if (response && response.createVideoSubClass) {
    yield put({
      type: reportAction.APEND_CLASS_DATA,
      payload: {
        subClassData: response.createVideoSubClass.videoSubClass,
      },
    })
    yield put({
      type: actions.GET_ANNOTATE_INIT,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        isSubClassDrawer: false,
      },
    })
    notification.success({
      message: 'Video SubClass added sucessfully',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassSaving: false,
    },
  })
}

export function* GET_VIDEO_BY_ID({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoDetailsLoading: true,
    },
  })
  const response = yield call(getVideoById, payload)
  if (response) {
    const { AWSVideoList } = yield select(state => state.annotation)
    const video = AWSVideoList.find(item => item.id === payload.id)
    yield put({
      type: actions.SET_STATE,
      payload: {
        videoDetails: response.videoUrl,
        videoList:
          !video && video === undefined
            ? [...AWSVideoList, { ...response.videoUrl }]
            : AWSVideoList,
        selectedClip: null,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoDetailsLoading: false,
    },
  })
}

export function* CREATE_VIDEO_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoSaving: true,
    },
  })
  const response = yield call(createVideoAnnotation, payload)
  if (response) {
    yield put({
      type: actions.APPEND_AWS_VIDEO,
      payload: {
        data: response.createVideoAnnotation.video,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoSaving: false,
    },
  })
}

export function* GET_STUDENTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isStudentLoading: true,
    },
  })
  const response = yield call(getStudents)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        students: response.getStudentInfo.edges,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isStudentLoading: false,
    },
  })
}

export function* GET_AWS_VIDEO_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAWSVideoListLoading: true,
    },
  })
  let { first, pageNo } = payload
  const { AWSVideoPageInfo, AWSVideoType } = yield select(state => state.annotation)

  let after = null
  let before = null
  let last = null

  if (pageNo === 1) {
    after = null
    before = null
  } else {
    if (AWSVideoType === 'next') {
      after = AWSVideoPageInfo?.endCursor
    }
    if (AWSVideoType === 'prev') {
      before = AWSVideoPageInfo?.startCursor
      last = first
      first = null
    }
  }
  const response = yield call(getAWSVideoList, {
    ...payload,
    after,
    before,
    first,
    last,
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        AWSVideoList: response.getVideos.edges.map(({ node }) => ({ ...node })),
        videoList: response.getVideos.edges.map(({ node }) => ({ ...node })),
        AWSCount: response.getVideos.totalCount,
        AWSVideoPageInfo: response.getVideos.pageInfo,
        // selectedAWSVideo: response.videos.length ? response.videos[0] : null,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAWSVideoListLoading: false,
    },
  })
}

export function* CREATE_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationSaving: true,
    },
  })
  const response = yield call(createAnnotation, payload)
  if (response) {
    notification.success({
      message: 'Annotation Created Successfully!!',
    })
    yield put({
      type: actions.APPEND_ANNOTATION,
      payload: {
        data: response.createAnnotation.annotationList,
      },
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        isCreateAnnotationDrawer: false,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationSaving: false,
    },
  })
}

export function* GET_ANNOTATE_INIT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotateInitLoading: true,
    },
  })
  const response = yield call(getAnnotateInit, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedSubClassAnnotateInit: response,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotateInitLoading: false,
    },
  })
}

export function* DELETE_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isDeleting: true,
    },
  })
  const response = yield call(deleteAnnotation, payload)
  if (response && response.deleteAnnotation.status) {
    notification.success({
      message: 'Annotation',
      description: `${response.deleteAnnotation.msg}`,
    })
    yield put({
      type: actions.POP_ANNOTATION,
      payload: {
        data: { ...payload },
      },
    })
    yield put({
      type: actions.POP_ANNOTATION,
      payload: {
        data: { ...payload },
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isDeleting: false,
    },
  })
}

export function* GET_VIDEO_CLASS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClassLoading: true,
    },
  })
  const response = yield call(getVideoClass)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        videoClass: response.videoClass,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClassLoading: false,
    },
  })
}

export function* GET_PREVIEW_ANVIL_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnvilDataLoading: true,
    },
  })
  const response = yield call(getAnvilData, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        anvilData: response.previewAnvilFile.anvilData,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnvilDataLoading: false,
    },
  })
}

export function* GENERATE_ANVIL_BACKGROUND({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnvilGeneratingBackground: true,
    },
  })
  const response = yield call(generateAnvilBackground, payload)
  if (response && response.generateAnvil.status) {
    notification.success({
      message: response.generateAnvil.message,
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnvilGeneratingBackground: false,
    },
  })
}

export function* UPDATE_VIDEO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoUserSaving: true,
    },
  })
  const response = yield call(updateVideo, payload)
  if (response) {
    notification.success({
      message: response.updateVideo.message,
    })
    yield put({
      type: actions.REPLACE_VIDEO,
      payload: {
        data: response.updateVideo.video,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoUserSaving: false,
    },
  })
}

export function* GET_ANNOTATION_STAFFS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationStaffLoading: true,
    },
  })
  const response = yield call(getAnnotationStaffs, payload)
  if (response && response.getAnnotationStaff) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        annotationStaffs: response.getAnnotationStaff.edges,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationStaffLoading: false,
    },
  })
}

export function* GET_INTERRATE_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isReportLoading: true,
    },
  })
  const response = yield call(getInterrateReport, payload)
  if (response && response.getInterrateAggrementSimilarity) {
    const data = JSON.parse(response.getInterrateAggrementSimilarity)
    yield put({
      type: actions.SET_STATE,
      payload: {
        reportData: data,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isReportLoading: false,
    },
  })
}
export function* GET_VIDEO_CLIPS_BY_CLASS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClipsLoading: true,
    },
  })

  const response = yield call(getVideoClipsByClass, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        annotationClips: response.videoClipsByVideoId.annotatorClips,
        // verifyingClips: response.videoClipsByVideoId.verifierClips,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClipsLoading: false,
    },
  })
}

export function* UPDATE_VIDEO_STATUS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      videoStatusSaving: true,
    },
  })

  const response = yield call(updateVideoSatus, payload)
  if (response && response.updateVideoStatusByAdmin.status) {
    notification.success({
      message: response.updateVideoStatusByAdmin.message,
    })
    yield put({
      type: actions.REPLACE_VIDEO_STATUS,
      payload: {
        data: response.updateVideoStatusByAdmin.video,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      videoStatusSaving: false,
    },
  })
}

export function* GET_CLASS_SUBCLASS_INFO() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassDataLoading: true,
    },
  })

  const response = yield call(getClassSubClassInfo)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        subClassData: response.videoClass,
        // verifyingClips: response.videoClipsByVideoId.verifierClips,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassDataLoading: false,
    },
  })
}

export function* GET_MORE_DROPDOWN() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isMoreDataLoading: true,
    },
  })

  try {
    const response = yield call(getMoreDropdown)
    if (response) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          behaviours: response.getBehaviours.edges,
          emotions: response.getEmotions.edges,
          jointAttentions: response.getJointAttentions.edges,
          prompts: response.getPrompts.edges,
          socialOrientation: response.getSocialOrientation.edges,
        },
      })
    }
  } catch (err) {
    console.log('Error', JSON.stringify(err))
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isMoreDataLoading: false,
    },
  })
}

export function* ADD_MORE_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSavingResponse: true,
    },
  })

  try {
    const response = yield call(addMoreData, payload)
    if (response && response.addEmotionBehaviourJointAttention.status) {
      notification.success({
        message: response.addEmotionBehaviourJointAttention.message,
      })

      yield put({
        type: actions.GET_MORE_DROPDOWN,
      })
    }
  } catch (err) {
    console.log('Error', JSON.stringify(err))
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isSavingResponse: false,
    },
  })
}

export function* GET_STUDENT_TARGETS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingTargets: true,
    },
  })

  try {
    const response = yield call(getStudentTargets, payload)
    if (response && response.getTargetByStudentId.edges) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          studentTargets: response.getTargetByStudentId.edges,
        },
      })
    }
  } catch (err) {
    console.log('Error', JSON.stringify(err))
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingTargets: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ANNOTATED_VIDEOS, GET_ANNOTATED_VIDEOS),
    takeEvery(actions.GET_VIDEO_CLIP, GET_VIDEO_CLIP),

    takeEvery(actions.GENERATE_ANVIL, GENERATE_ANVIL),
    takeEvery(actions.DELETE_VIDEO_ANNOTATION, DELETE_VIDEO_ANNOTATION),
    takeEvery(actions.CREATE_VIDEO_SUB_CLASS, CREATE_VIDEO_SUB_CLASS),
    takeEvery(actions.GET_VIDEO_BY_ID, GET_VIDEO_BY_ID),
    takeEvery(actions.CREATE_VIDEO_ANNOTATION, CREATE_VIDEO_ANNOTATION),
    takeEvery(actions.GET_STUDENTS, GET_STUDENTS),
    takeEvery(actions.GET_AWS_VIDEO_LIST, GET_AWS_VIDEO_LIST),
    takeEvery(actions.CREATE_ANNOTATION, CREATE_ANNOTATION),
    takeEvery(actions.GET_ANNOTATE_INIT, GET_ANNOTATE_INIT),
    takeEvery(actions.DELETE_ANNOTATION, DELETE_ANNOTATION),
    takeEvery(actions.GET_VIDEO_CLASS, GET_VIDEO_CLASS),
    takeEvery(actions.GET_PREVIEW_ANVIL_DATA, GET_PREVIEW_ANVIL_DATA),
    takeEvery(actions.GENERATE_ANVIL_BACKGROUND, GENERATE_ANVIL_BACKGROUND),
    takeEvery(actions.UPDATE_VIDEO, UPDATE_VIDEO),
    takeEvery(actions.GET_ANNOTATION_STAFFS, GET_ANNOTATION_STAFFS),
    takeEvery(actions.GET_INTERRATE_REPORT, GET_INTERRATE_REPORT),
    takeEvery(actions.GET_VIDEO_CLIPS_BY_CLASS, GET_VIDEO_CLIPS_BY_CLASS),
    takeEvery(actions.UPDATE_VIDEO_STATUS, UPDATE_VIDEO_STATUS),
    takeEvery(actions.GET_CLASS_SUBCLASS_INFO, GET_CLASS_SUBCLASS_INFO),
    takeLatest(actions.GET_MORE_DROPDOWN, GET_MORE_DROPDOWN),
    takeEvery(actions.ADD_MORE_DATA, ADD_MORE_DATA),
    takeLatest(actions.GET_STUDENT_TARGETS, GET_STUDENT_TARGETS),
  ])
}
