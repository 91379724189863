import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GetStudentNameById,
  GetUserDetailsByUsername,
  login,
  logout,
  RefreshToken,
} from 'services/user'

import actions from './actions'

export function* LOGIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(login, payload)
  // console.log('payload123', payload)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: response.tokenAuth.user.id,
        authorized: true,
        loading: false,
        firstLogin: response.tokenAuth.firstLogin || '',
        practroRedirect: payload.practroRedirect,
        role:
          response.tokenAuth.user.groups.edges.length > 0
            ? response.tokenAuth.user.groups.edges[0].node.name
            : '',
      },
    })

    if (response.tokenAuth.user.groups.edges.length > 0) {
      if (response.tokenAuth.user.groups.edges[0].node.name === 'annotation_staff') {
        localStorage.setItem('userId', JSON.stringify(response.tokenAuth.user.id))

        yield put({
          type: 'user/SET_STATE',
          payload: {
            annotationStaff: response.tokenAuth.user.annotationstaff,
          },
        })
      }
    }

    yield put({
      type: 'menu/GET_DATA',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      // role:JSON.parse(localStorage.getItem('role')),
    },
  })

  const response = yield call(RefreshToken)

  if (response && response.refreshToken) {
    const result = yield call(GetUserDetailsByUsername, response.refreshToken.payload.username)

    if (result && result.data.getuser?.edges.length > 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: result.data.getuser.edges[0].node.id,
          authorized: true,
          loading: false,
          role: result.data.getuser.edges[0].node.groups.edges[0].node.name,
          // role: result.data.getuser.edges[0].node.groups[0].node.id,
        },
      })

      if (result.data.getuser.edges[0].node.groups.edges[0].node.name === 'annotation_staff') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            annotationStaff: result.data.getuser.edges[0].node.annotationstaff,
          },
        })
      }
    }
  } else {
    localStorage.clear()
    LOGOUT()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
      role: '',
    },
  })
  yield put({
    type: 'family/SET_STATE',
    payload: {
      familyMembers: [],
      loading: false,
    },
  })
  yield call(logout)
  window.location.reload()
  localStorage.clear()
}

export function* GET_STUDENT_NAME() {
  const response = yield call(GetStudentNameById, localStorage.getItem('studentId'))
  if (response && response.data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        studentName: response.data.student.firstname,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.GET_STUDENT_NAME, GET_STUDENT_NAME),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
