import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Menu from 'components/LayoutComponents/Menu'

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isMenuVisible: settings.isMenuVisible,
})

const MainLayout = ({
  children,
  isBorderless,
  isSquaredBorders,
  isFixedWidth,
  isMenuShadow,
  isMenuTop,
  isMenuVisible,
}) => {
  return (
    <Layout
      className={classNames({
        settings__borderLess: isBorderless,
        settings__squaredBorders: isSquaredBorders,
        settings__fixedWidth: isFixedWidth,
        settings__menuShadow: isMenuShadow,
        settings__menuTop: isMenuTop,
      })}
    >
      {isMenuVisible && <Menu />}

      <Layout>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className="" style={{ padding: 0, margin: 0, maxWidth: '100%', height: '100%' }}>
            {children}
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
