const actions = {
  SET_STATE: 'transcripts/SET_STATE',
  GET_UNASSIGNED_TRANSCRIPT_LIST: 'transcripts/GET_UNASSIGNED_TRANSCRIPT_LIST',
  GET_ALL_TRANSCRIPT_LIST: 'transcripts/GET_ALL_TRANSCRIPT_LIST',
  GET_ALL_TRANSCRIPT_LIST_BY_STAFF: 'transcripts/GET_ALL_TRANSCRIPT_LIST_BY_STAFF',

  // Upload Transcript
  GET_VIDEOS_FOR_TRANSCRIPT: 'transcripts/GET_VIDEOS_FOR_TRANSCRIPT',
  UPLOAD_VIDEO_TRANSCRIPT: 'transcripts/UPLOAD_VIDEO_TRANSCRIPT',
  APPEND_VIDEO_TRANSCRIPT: 'transcripts/APPEND_VIDEO_TRANSCRIPT',

  // Assign Transcripts
  ASSIGN_TRANSCRIPTS_TO_STAFF: 'transcripts/ASSIGN_TRANSCRIPTS_TO_STAFF',
  REMOVE_ASSIGNED_TRANSCRIPT: 'transcripts/REMOVE_ASSIGNED_TRANSCRIPT',
  GET_STAFFS: 'transcripts/GET_STAFFS',

  // View Transcript screen
  GET_TRANSCRIPTS_DATA: 'transcripts/GET_TRANSCRIPTS_DATA',
  APPEND_RECORDS: 'transcripts/APPEND_RECORDS',

  UPDATE_SPEAKER: 'transcripts/UPDATE_SPEAKER',
  UPDATE_CLASS: 'transcripts/UPDATE_CLASS',
  UPDATE_SUB_CLASS: 'transcripts/UPDATE_SUB_CLASS',
  UPDATE_TRANSCRIPT_DATA: 'transcripts/UPDATE_TRANSCRIPT_DATA',
  ADD_BLOCK_RESPONSE: 'transcripts/ADD_BLOCK_RESPONSE',
  UPDATE_BLOCK_IN_TRANSCRIPT_DATA: 'transcripts/UPDATE_BLOCK_IN_TRANSCRIPT_DATA',
  ADD_BLOCK: 'transcripts/ADD_BLOCK',
}

export default actions
