import { notification } from 'antd'
import { all, put, takeEvery, call, select, takeLatest } from 'redux-saga/effects'
import { getLearners, getTargets, manageTarget, getTargetTemplate } from 'services/targets'
import annotationActions from 'redux/Annotation/actions'
import actions from './actions'

export function* GET_LEARNERS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  const response = yield call(getLearners, payload)
  if (response) {
    const { learners, selectedLearner } = yield select(state => state.targets)
    const learnersData =
      payload && payload.firstname
        ? response.getStudentInfo.edges
        : [...learners, ...response.getStudentInfo.edges]
    yield put({
      type: actions.SET_STATE,
      payload: {
        learnerPageInfo: response.getStudentInfo.pageInfo,
        learners: learnersData,
        selectedLearner:
          !selectedLearner && learnersData.length ? learnersData[0].node : selectedLearner,
        learnersFetched: true,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}

export function* GET_TARGETS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTargetLoading: true,
    },
  })
  const response = yield call(getTargets, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        targets: response.getTargetByStudentId.edges,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTargetLoading: false,
    },
  })
}

export function* ADD_OR_EDIT_TARGET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTargetSaving: true,
    },
  })
  const response = yield call(manageTarget, payload)
  if (response && response.createOrUpdateTarget.status) {
    notification.success({
      message: response.createOrUpdateTarget.message,
      duration: 1,
    })
    if (payload.targetId) {
      // update response in redux
      yield put({
        type: actions.UPDATE_TARGET,
        payload: {
          target: response.createOrUpdateTarget.studentTarget,
        },
      })
    } else {
      // add respone in redux
      yield put({
        type: actions.APPEND_TARGET,
        payload: {
          target: response.createOrUpdateTarget.studentTarget,
        },
      })

      yield put({
        type: annotationActions.APPEND_TARGET,
        payload: {
          target: response.createOrUpdateTarget.studentTarget,
        },
      })
    }
    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     targets: response.getTargetByStudentId.edges,
    //   },
    // })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTargetSaving: false,
    },
  })
}

export function* GET_TARGETS_TEMPLATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTemplateLoading: true,
    },
  })

  try {
    const response = yield call(getTargetTemplate, payload)
    if (response && response.getTargetEntryTemplate) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          TargetTemplate: response.getTargetEntryTemplate,
        },
      })
    }
  } catch (err) {
    console.log('Error in getting targets template', JSON.stringify(err))
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isTemplateLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_LEARNERS, GET_LEARNERS)])
  yield all([takeLatest(actions.GET_TARGETS, GET_TARGETS)])
  yield all([takeLatest(actions.ADD_OR_EDIT_TARGET, ADD_OR_EDIT_TARGET)])
  yield all([takeLatest(actions.GET_TARGETS_TEMPLATE, GET_TARGETS_TEMPLATE)])
}
