const actions = {
  SET_STATE: 'annotation/SET_STATE',
  GET_ANNOTATED_VIDEOS: 'annotation/GET_ANNOTATED_VIDEOS',
  GENERATE_ANVIL: 'annotation/GENERATE_ANVIL',
  DELETE_VIDEO_ANNOTATION: 'annotation/DELETE_VIDEO_ANNOTATION',
  POP_VIDEO_ANNOTATION: 'annotation/POP_VIDEO_ANNOTATION',
  PAGE_CHANGED: 'annotation/PAGE_CHANGED',
  CREATE_VIDEO_SUB_CLASS: 'annotation/CREATE_VIDEO_SUB_CLASS',
  GET_VIDEO_BY_ID: 'annotation/GET_VIDEO_BY_ID',
  CREATE_VIDEO_ANNOTATION: 'annotation/CREATE_VIDEO_ANNOTATION',
  GET_STUDENTS: 'annotation/GET_STUDENTS',
  GET_AWS_VIDEO_LIST: 'annotation/GET_AWS_VIDEO_LIST',
  APPEND_AWS_VIDEO: 'annotation/APPEND_AWS_VIDEO',
  GET_VIDEO_CLASS: 'annotation/GET_VIDEO_CLASS',
  GET_VIDEO_CLIP: 'annotation/GET_VIDEO_CLIP',
  GET_ANNOTATE_INIT: 'annotation/GET_ANNOTATE_INIT',
  CREATE_ANNOTATION: 'annotation/CREATE_ANNOTATION',
  APPEND_ANNOTATION: 'annotation/APPEND_ANNOTATION',
  APPEND_ANNOTATION_CLIP: 'annotation/APPEND_ANNOTATION_CLIP',
  REPLACE_ANNOTATION_CLIP: 'annotation/REPLACE_ANNOTATION_CLIP',
  DELETE_ANNOTATION: 'annotation/DELETE_ANNOTATION',
  POP_ANNOTATION: 'annotation/POP_ANNOTATION',
  GET_PREVIEW_ANVIL_DATA: 'annotation/GET_PREVIEW_ANVIL_DATA',
  GENERATE_ANVIL_BACKGROUND: 'annotation/GENERATE_ANVIL_BACKGROUND',
  UPDATE_VIDEO: 'annotation/UPDATE_VIDEO',
  REPLACE_VIDEO: 'annotation/REPLACE_VIDEO',
  GET_ANNOTATION_STAFFS: 'annotation/GET_ANNOTATION_STAFFS',
  GET_INTERRATE_REPORT: 'annotation/GET_INTERRATE_REPORT',
  GET_VIDEO_CLIPS_BY_CLASS: 'annotation/GET_VIDEO_CLIPS_BY_CLASS',
  UPDATE_VIDEO_STATUS: 'annotation/UPDATE_VIDEO_STATUS',
  REPLACE_VIDEO_STATUS: 'annotation/REPLACE_VIDEO_STATUS',
  GET_CLASS_SUBCLASS_INFO: 'annotation/GET_CLASS_SUBCLASS_INFO',
  GET_MORE_DROPDOWN: 'annotation/GET_MORE_DROPDOWN',
  GET_STUDENT_TARGETS: 'annotation/GET_STUDENT_TARGETS',
  ADD_MORE_DATA: 'annotation/ADD_MORE_DATA',
  APPEND_TARGET: 'annotation/APPEND_TARGET',
}

export default actions
