import Loader from 'components/LayoutComponents/Loader'
import { ConnectedRouter } from 'connected-react-router'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import React from 'react'
import Loadable from 'react-loadable'
import { Redirect, Route, Switch } from 'react-router-dom'
import PushNotification from 'pages/PushNotification'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Annotation test routes

  {
    path: '/annotation/add-video',
    component: loadable(() => import('pages/AnnotationVideos/AddVideo')),
    exact: true,
  },
  {
    path: '/annotation/upload-video',
    component: loadable(() => import('pages/AnnotationVideos/UploadVideo')),
    exact: true,
  },
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/new-login',
    component: loadable(() => import('pages/user/login/NewLogin')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/forgotpass',
    component: loadable(() => import('pages/user/forgot/changepass')),
    exact: true,
  },
  {
    path: '/push_notification',
    component: PushNotification,
  },
  {
    path: '/highlight',
    component: loadable(() => import('pages/AnnotationPDF')),
    exact: true,
  },

  // Annotation partners admin login
  {
    path: '/partners/annotationStaffs',
    component: loadable(() => import('pages/AnnotationStaffs')),
    exact: true,
  },
  {
    path: '/partners/annotationLearners',
    component: loadable(() => import('pages/AnnotationLearners')),
    exact: true,
  },

  // Annotation DashBoard admin
  {
    path: '/annotationdashboard',
    component: loadable(() => import('pages/annotationdashBoard')),
    // exact: true,
  },
  {
    path: '/annotationView/:videoId',
    component: loadable(() => import('pages/annotationdashBoard/Dashboard')),
    // exact: true,
  },

  {
    path: '/annotation/annotated-video',
    component: loadable(() => import('pages/AnnotationVideos/AnnotatedVideo')),
    exact: true,
  },

  // PDF Annotation admin
  {
    path: '/pdf-annotation',
    component: loadable(() => import('pages/AnnotationPDF/Annotations')),
    exact: true,
  },
  {
    path: '/pdf-annotation/:pdfId',
    component: loadable(() => import('pages/AnnotationPDF/Annotations/annotation')),
    exact: true,
  },

  // Annotation staff login
  {
    path: '/annotationStaff/annotation',
    component: loadable(() => import('pages/annotationdashBoardForStaff')),
    exact: true,
  },
  {
    path: '/annotatorPlay/:videoId',
    component: loadable(() =>
      import('pages/annotationdashBoardForStaff/AnnotationVideos/AnnotatorPlay'),
    ),
    exact: true,
  },
  {
    path: '/verifierPlay/:videoId',
    component: loadable(() =>
      import('pages/annotationdashBoardForStaff/VerifyingVideos/VerifierPlay'),
    ),
    exact: true,
  },
  {
    path: '/dashboard/therapist_admin',
    component: loadable(() => import('pages/Dashboard/Therapist')),
    exact: true,
  },
  {
    path: '/annotation/annotated-clips',
    component: loadable(() => import('pages/AnnotationClips')),
    exact: true,
  },
  {
    path: '/annotation/unassigned-videos',
    component: loadable(() => import('pages/AssignVideosToStaff')),
    exact: true,
  },
  {
    path: '/reports/videos',
    component: loadable(() => import('pages/Reports/Videos')),
    exact: true,
  },
  {
    path: '/reports/staff',
    component: loadable(() => import('pages/Reports/Staff')),
    exact: true,
  },
  {
    path: '/reports/classes',
    component: loadable(() => import('pages/Reports/ClassAndSubClass')),
    exact: true,
  },
  {
    path: '/reports/class',
    component: loadable(() => import('pages/Reports/Class')),
    exact: true,
  },
  {
    path: '/reports/class2',
    component: loadable(() => import('pages/Reports/Class2')),
    exact: true,
  },
  {
    path: '/reports/annotations-count',
    component: loadable(() => import('pages/Reports/AnnotationsCounts')),
    exact: true,
  },

  // Transcripts routes
  {
    path: '/Alltranscripts/list',
    component: loadable(() => import('pages/TranscriptAnnotation/AllTranscripts')),
    exact: true,
  },
  {
    path: '/transcripts/list',
    component: loadable(() => import('pages/TranscriptAnnotation/Transcripts')),
    exact: true,
  },
  {
    path: '/transcripts/view/:transcriptId',
    component: loadable(() => import('pages/TranscriptAnnotation/ViewTranscripts')),
    exact: true,
  },
  {
    path: '/targets',
    component: loadable(() => import('pages/Targets')),
    exact: true,
  },
]

const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/user/login" />} />
          {routes.map(route => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}
          {/* <Route component={} path='/Registration/' /> */}
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  )
}

export default Router
