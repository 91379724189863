const ANNOTATION_VIDEOS = {
  SET_STATE: 'annotationVideos/SET_STATE',
  PAGE_CHANGED: 'annotationVideos/PAGE_CHANGED',
  GET_ANNOTATION_STAFFS: 'annotationVideos/GET_ANNOTATION_STAFFS',

  GET_VIDEOS: 'annotationVideos/GET_VIDEOS',
  GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN: 'annotationVideos/GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN',
  GET_ANNOTATION_STAFF_BY_ID: 'annotationVideos/GET_ANNOTATION_STAFF_BY_ID',
  GET_ASSIGNED_VIDEOS_AS_ANNOTATOR: 'annotationVideos/GET_ASSIGNED_VIDEOS_AS_ANNOTATOR',
  ASSIGN_VIDEO_TO_ANNOTATOR: 'annotationVideos/ASSIGN_VIDEO_TO_ANNOTATOR',
  REMOVE_ASSIGNED_VIDEOS: 'annotationVideos/REMOVE_ASSIGNED_VIDEOS',

  GET_ASSIGNED_VIDEOS_AS_VERIFIER: 'annotationVideos/GET_ASSIGNED_VIDEOS_AS_VERIFIER',
  ASSIGN_VIDEO_TO_VERIFIER: 'annotationVideos/ASSIGN_VIDEO_TO_VERIFIER',
  APPEND_ASSIGNED_VERIFY_VIDEO: 'annotationVideos/APPEND_ASSIGNED_VERIFY_VIDEO',
}

export default ANNOTATION_VIDEOS
