import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
// import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import { createHashHistory } from 'history'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Router from 'router'
import { ApolloProvider } from '@apollo/client'
import client from 'apollo/config'
import * as serviceWorker from './serviceWorker'

// app styles
import './global.scss'

const history = createHashHistory()
const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development' && true) {
  middlewares.push(logger)
}

const store = configureStore({
  reducer: reducers(history),
  middleware: middlewares,
  // devTools: process.env.NODE_ENV === 'development',
})

// const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

const root = createRoot(document.getElementById('root'))

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router history={history} />
    </Provider>
  </ApolloProvider>,
)

// serviceWorker.register()
serviceWorker.unregister()
export { store, history }
