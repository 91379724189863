/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getVideosStatusCount() {
  return apolloClient
    .query({
      query: gql`
        query {
          getVideosDashboardBySuperadmin
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function loadStaffData(payload) {
  return apolloClient
    .query({
      query: gql`
        query(
          $staffType: String!
          $dataType: String!
          $dayStartDate: Date
          $dayEndDate: Date
          $weeksOrMonths: [DateTimeObjectType]
        ) {
          getStaffReport(
            staffType: $staffType
            dataType: $dataType
            dayStartDate: $dayStartDate
            dayEndDate: $dayEndDate
            weeksOrMonths: $weeksOrMonths
          ) {
            dataType
            staffType
            data {
              staffData {
                id
                firstname
                lastname
                email
                createdAt

                totalAnnotatorVideos
                pendingAnnotateVideos
                annotatedVideos
                annotatedClipsToAnnotator

                assignedClipsToVerifier
                verifiedClipsByVerifier
                deletedClipsByVerifier
                rfmlClipsByVerifier
              }
              data {
                date
                assignedVideosCount
                totalAnnotatedClips
                assignedVideosClipsCount
                totalVerifiedClips
                markDeleteVideosClips
                markRmltVideosClips
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function loadClassesData(payload) {
  return apolloClient
    .query({
      query: gql`
        query(
          $status: String!
          $first: Int
          $videoName: String
          $offset: Int
          $annotatedBy: ID
          $verifiedBy: ID
        ) {
          getVideoClassReport(
            status: $status
            first: $first
            videoName: $videoName
            offset: $offset
            annotatedBy: $annotatedBy
            verifiedBy: $verifiedBy
          ) {
            totalCount
            data {
              video {
                id
                name
                createdAt
                duration
                annotator {
                  id
                  firstname
                  lastname
                }
                verifier {
                  id
                  firstname
                  lastname
                }
              }
              subclassCount
              classCount
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoClassAndSubClass() {
  return apolloClient
    .query({
      query: gql`
        query {
          videoClass {
            id
            name
            videosubclassSet {
              edges {
                node {
                  id
                  name
                  feature {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  response {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  category {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  stimulus {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function loadAnnotationsCountsData(payload) {
  return apolloClient
    .query({
      query: gql`
        query($videoStatus: String, $endDate: Date, $startDate: Date) {
          getClassSubclassCountReport(
            videoStatus: $videoStatus
            startDate: $startDate
            endDate: $endDate
          )
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function addDataInSubclass(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation addDataInClass(
          $classId: ID!
          $subClassId: ID!
          $stimulus: [String]
          $response: [String]
          $category: [String]
          $feature: [String]
        ) {
          addDataInClass(
            input: {
              classId: $classId
              subclassId: $subClassId
              stimulus: $stimulus
              response: $response
              category: $category
              feature: $feature
            }
          ) {
            status
            message
            videoClass {
              id
              name
              videosubclassSet {
                edges {
                  node {
                    id
                    name
                    feature {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    stimulus {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    category {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    response {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createClass(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createVideoClass($name: String!) {
          createVideoClass(input: { name: $name }) {
            videoClass {
              id
              name
              videosubclassSet {
                edges {
                  node {
                    id
                    name
                    feature {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    stimulus {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    category {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    response {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
