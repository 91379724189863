/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-const */
import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import {
  createDiagnosis,
  createUpdateAnnotationLearner,
  getAnnotationLearners,
  getDiagnosis,
} from 'services/annotationLearners'

import { notification } from 'antd'
import ANNOTATION_LEARNERS from './actions'

export function* GET_ANNOTATION_LEARNERS({ payload }) {
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isLearnersLoading: true,
    },
  })

  const response = yield call(getAnnotationLearners, { ...payload })
  if (response) {
    yield put({
      type: ANNOTATION_LEARNERS.SET_STATE,
      payload: {
        annotationLearners: response.getStudentInfo.edges,
        learnerCount: response.getStudentInfo.totalCount,
      },
    })
  }
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isLearnersLoading: false,
    },
  })
}

export function* CREATE_UPDATE_ANNOTATION_LEARNER({ payload }) {
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isLearnerSaving: true,
    },
  })

  const response = yield call(createUpdateAnnotationLearner, payload)
  if (response && response.createUpdateStudentInfo.status) {
    notification.success({
      message: response.createUpdateStudentInfo.message,
    })
    yield put({
      type: ANNOTATION_LEARNERS.REPLACE_ANNOTATION_LEARNER,
      payload: {
        data: response.createUpdateStudentInfo.studentInfo,
      },
    })
  }
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isLearnerSaving: false,
    },
  })
}

export function* GET_DIAGNOSIS() {
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isDiagnosisLoading: true,
    },
  })

  const response = yield call(getDiagnosis)
  if (response) {
    yield put({
      type: ANNOTATION_LEARNERS.SET_STATE,
      payload: {
        diagnosis: response.getDiagnosis.edges,
      },
    })
  }
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isDiagnosisLoading: false,
    },
  })
}

export function* CREATE_DIAGNOSIS({ payload }) {
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isDiagnosisSaving: true,
    },
  })

  const response = yield call(createDiagnosis, payload)
  if (response && response.createDiagnosis.status) {
    notification.success({
      message: response.createDiagnosis.message,
    })
    yield put({
      type: ANNOTATION_LEARNERS.APPEND_DIAGNOSIS,
      payload: {
        data: response.createDiagnosis.diagnosisInfo,
      },
    })
  }
  yield put({
    type: ANNOTATION_LEARNERS.SET_STATE,
    payload: {
      isDiagnosisSaving: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(ANNOTATION_LEARNERS.GET_ANNOTATION_LEARNERS, GET_ANNOTATION_LEARNERS),
    takeLatest(
      ANNOTATION_LEARNERS.CREATE_UPDATE_ANNOTATION_LEARNER,
      CREATE_UPDATE_ANNOTATION_LEARNER,
    ),
    takeLatest(ANNOTATION_LEARNERS.CREATE_DIAGNOSIS, CREATE_DIAGNOSIS),
    takeLatest(ANNOTATION_LEARNERS.GET_DIAGNOSIS, GET_DIAGNOSIS),
  ])
}
