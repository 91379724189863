const actions = {
  SET_STATE: 'annotationPDF/SET_STATE',
  GET_ANNOTATED_PDF: 'annotationPDF/GET_ANNOTATED_PDF',
  CREATE_PDF_ANNOTATION: 'annotationPDF/CREATE_PDF_ANNOTATION',
  GET_PDF_BY_ID: 'annotationPDF/GET_PDF_BY_ID',
  ADD_ANNOTATION: 'annotationPDF/ADD_ANNOTATION',
  EDIT_ANNOTATION: 'annotationPDF/EDIT_ANNOTATION',
  DELETE_ANNOTATION: 'annotationPDF/DELETE_ANNOTATION',
  DELETE_ALL_ANNOTATION: 'annotationPDF/DELETE_ALL_ANNOTATION',
  GET_PDF_ANNOTATION_LEVEL1: 'annotationPDF/GET_PDF_ANNOTATION_LEVEL1',
  LOAD_OTHER_LEVEL_BY_LEVEL1: 'annotation/LOAD_OTHER_LEVEL_BY_LEVEL1',
}

export default actions
