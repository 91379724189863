/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getAnnotationStaffs(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAnnotationStaff {
          getAnnotationStaff {
            edges {
              node {
                id
                email
                firstname
                lastname
                isAnnotator
                isVerifier
                totalAnnotatorVideos
                pendingAnnotateVideos
                annotatedVideos
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotationStaffByID(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAnnotationStaff($id: String) {
          getAnnotationStaff(id: $id) {
            edges {
              node {
                id
                email
                firstname
                lastname
                isAnnotator
                isVerifier
                totalAnnotatorVideos
                pendingAnnotateVideos
                annotatedVideos
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideos(payload) {
  return apolloClient
    .query({
      query: gql`
        query getVideos($name: String) {
          getVideos(first: 30, name_Icontains: $name) {
            totalCount
            edges {
              node {
                id
                name
                url
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getUnassignedVideosBySuperadmin(payload) {
  return apolloClient
    .query({
      query: gql`
        # staffType: [annotator, verifier]
        query getUnassignedVideosBySuperadmin(
          $staffType: String!
          $first: Int
          $name: String
          $status: String
          $offset: Int
        ) {
          getUnassignedVideosBySuperadmin(
            staffType: $staffType
            first: $first
            name_Icontains: $name
            status: $status
            offset: $offset
          ) {
            totalCount
            edges {
              node {
                id
                name
                signedUrl
                student {
                  id
                }
                createdAt
                updatedAt
                status
                duration
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAssignVideosAsAnnotator(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAssignedVideosBySuperadmin($staffId: ID!, $name: String) {
          getAssignedVideosBySuperadmin(
            staffType: "annotator"
            annotationStaff: $staffId
            name_Icontains: $name
          ) {
            totalCount
            edges {
              node {
                id
                name
                status
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function assignVideoToAnnotator(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignVideoToAnnotator($staffId: ID!, $videos: [ID]) {
          assignVideoToAnnotator(input: { annotationStaff: $staffId, annotatorVideos: $videos }) {
            message
            status
            assignedVideos {
              id
              name
              annotator {
                id
                firstname
                lastname
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function assignVideoToVerifier(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignVideoToAnnotatorAndVerifier($staffId: ID!, $videos: [ID]) {
          assignVideoToAnnotatorAndVerifier(
            input: { annotationStaff: $staffId, verifierVideos: $videos }
          ) {
            message
            status
            assignedVideos {
              id
              name
              annotator {
                id
                firstname
                lastname
                email
              }
              verifier {
                id
                firstname
                lastname
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function getAssignVideosAsVerifier(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAssignedVideosBySuperadmin($staffId: ID!) {
          getAssignedVideosBySuperadmin(staffType: "verifier", annotationStaff: $staffId) {
            totalCount
            edges {
              node {
                id
                name
                status
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
