/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { PLAY_DRAWER } from 'assets/styles/globalStyles'
import { USER_TYPES } from 'constants/user'
import { all, put, takeEvery, call, select, takeLatest } from 'redux-saga/effects'
import ANNOTATION_FOR_STAFF from 'redux/AnnotationForStaff/actions'
import ANNOTATION from 'redux/Annotation/actions'
import {
  getVideoAnnotations,
  getVideoClass,
  getAnnotationStaff,
  saveAnnotationDescription,
  deleteAnnotationClip,
  updateAnnotationSubclass,
  getVideoClipReportByVerifier,
  getVideos,
  createAnnotation,
  assignBulkVideoClipsToVerifier,
  getVideoClipReportByAnnotator,
  createUpdateAnnotationByAnnotator,
  assignSelectedClipsToVerifier,
  VerifiedToPending,
  massStatusUpdate,
  selectedClipStatusUpdate,
} from 'services/annotationsClips'
import { getAnnotationStatusFromValue } from 'utilities'
import _ from 'lodash'
import actions from './actions'

export function* GET_VIDEO_CLASS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClassLoading: true,
    },
  })
  const response = yield call(getVideoClass)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        videoClass: response.videoClass,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoClassLoading: false,
    },
  })
}

const groupDataByResponseId = data => {
  // Sort the data by feature.id
  const sortedData = _.sortBy(data, 'node.response.name')
  // Group the sorted data by feature.id
  const groupedData = _.groupBy(sortedData, 'node.response.name')

  return groupedData
}

const groupDataByCategoryId = data => {
  // Sort the data by feature.id
  const sortedData = _.sortBy(data, 'node.category.name')
  // Group the sorted data by feature.id
  const groupedData = _.groupBy(sortedData, 'node.category.name')

  return groupedData
}
const groupDataByStimulusId = data => {
  // Sort the data by feature.id
  const sortedData = _.sortBy(data, 'node.stimulus.name')
  // Group the sorted data by feature.id
  const groupedData = _.groupBy(sortedData, 'node.stimulus.name')

  return groupedData
}
const groupDataByFeatureId = data => {
  // Sort the data by feature.id
  const sortedData = _.sortBy(data, 'node.feature.name')
  // Group the sorted data by feature.id
  const groupedData = _.groupBy(sortedData, 'node.feature.name')

  return groupedData
}

export function* GET_VIDEO_ANNOTATIONS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnotatedClipsLoading: true,
    },
  })

  const { role, annotationStaff } = yield select(state => state.user)
  const { selectedTab } = yield select(state => state.annotationForStaff)

  if (
    role === USER_TYPES.AnnotationStaff &&
    annotationStaff &&
    annotationStaff.isVerifier &&
    selectedTab === 'verifier'
  ) {
    const response = yield call(getVideoClipReportByVerifier, {
      ...payload,
    })
    if (response) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          annotatedClips: response.videoClipReportByVerifier.edges.map(({ node }) => ({ ...node })),
          videoClipsCount: response.videoClipReportByVerifier.totalCount,
        },
      })
    }
  } else if (
    role === USER_TYPES.AnnotationStaff &&
    annotationStaff &&
    annotationStaff.isAnnotator &&
    selectedTab === 'annotator'
  ) {
    const response = yield call(getVideoClipReportByAnnotator, {
      ...payload,
    })
    if (response) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          annotatedClips: response.videoClipReportByAnnotator.edges.map(({ node }) => ({
            ...node,
          })),
          videoClipsCount: response.videoClipReportByAnnotator.totalCount,
        },
      })
    }
  } else {
    // const classId = 'VmlkZW9DbGFzc1R5cGU6OA=='
    // const subClassId = 'VmlkZW9TdWJDbGFzc1R5cGU6Nzk='
    // const isUnassignedVideos = true
    // const totalCountResponse = yield call(getVideoAnnotations, {
    //   ...payload,
    //   videoClass: classId,
    //   subclass: subClassId,
    //   isUnassignedVideos,
    // })
    // const { totalCount } = totalCountResponse.videoClipReportNew

    // const chunkSize = 10 // Adjust the chunk size as needed
    // const totalIterations = Math.ceil(totalCount / chunkSize)
    // const allData = []
    // console.log('totalIterations==>', totalIterations)

    // for (let iteration = 0; iteration < totalIterations; iteration++) {
    //   const offset = iteration * chunkSize
    //   console.log('current==>', iteration, offset)

    //   const response = yield call(getVideoAnnotations, {
    //     ...payload,
    //     videoClass: classId,
    //     subclass: subClassId,
    //     isUnassignedVideos,
    //     offset,
    //     pageSize: chunkSize,
    //   })

    //   if (response) {
    //     // Concatenate the data to the existing array
    //     allData.push(...response.videoClipReportNew.edges)
    //   }
    //   console.log('allData==>', allData)
    // }
    // const featureData = groupDataByFeatureId(allData)
    // console.log('feature==>', allData, featureData)
    // const stimulusData = groupDataByStimulusId(allData)
    // console.log('stimulusData==>', stimulusData)
    // const categoryData = groupDataByCategoryId(allData)
    // console.log('categoryData==>', categoryData)
    // const responseData = groupDataByResponseId(allData)
    // console.log('responseData==>', responseData)
    const response = yield call(getVideoAnnotations, { ...payload })
    if (response) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          annotatedClips: response.videoClipReportNew.edges.map(({ node }) => ({ ...node })),
          videoClipsCount: response.videoClipReportNew.totalCount,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnotatedClipsLoading: false,
    },
  })
}

export function* GET_STAFFS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isStaffLoading: true,
    },
  })
  const response = yield call(getAnnotationStaff)
  if (response) {
    const annotators = []
    const verifiers = []
    const { edges } = response.getAnnotationStaff
    if (edges.length) {
      for (let i = 0; i < edges.length; i++) {
        if (edges[i].node.isAnnotator) annotators.push(edges[i].node)
        if (edges[i].node.isVerifier) verifiers.push(edges[i].node)
      }
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        annotators,
        verifiers,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isStaffLoading: false,
    },
  })
}

export function* SAVE_DESCRIPTION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isDescriptionSaving: true,
    },
  })

  const response = yield call(saveAnnotationDescription, payload)
  if (response && response.updateVideoAnnotationNote.status) {
    notification.success({
      message: response.updateVideoAnnotationNote.message,
      duration: 1,
    })
    const { annotatedClips } = yield select(state => state.annotationClips)
    yield put({
      type: actions.SET_STATE,
      payload: {
        annotatedClips: annotatedClips.map(item => {
          if (item.id === payload.objectId) {
            return { ...item, note: response.updateVideoAnnotationNote.videoClip.note }
          }
          return item
        }),
      },
    })
  } else {
    notification.info({
      message: response.updateVideoAnnotationNote.message,
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isDescriptionSaving: false,
    },
  })
}
export function* VERIFIED_TO_PENDING({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isStatusSaving: true,
    },
  })

  const response = yield call(VerifiedToPending, payload)
  if (response && response.updateVideoClipStatusByAdmin.status) {
    notification.success({
      message: response.updateVideoClipStatusByAdmin.message,
      duration: 1,
    })
    const { annotatedClips, selectedClip } = yield select(state => state.annotationClips)
    yield put({
      type: actions.SET_STATE,
      payload: {
        annotatedClips: annotatedClips.map(item => {
          if (item.id === payload.videoClipId) {
            return {
              ...item,
              ...response.updateVideoClipStatusByAdmin.videoClip,
            }
          }
          return item
        }),
        selectedClip: {
          ...selectedClip,
          ...response.updateVideoClipStatusByAdmin.videoClip,
        },
        isAnnotationStatusModal: false,
      },
    })
  } else {
    notification.info({
      message: response.updateVideoClipStatusByAdmin.message,
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isStatusSaving: false,
    },
  })
}

export function* DELETE_ANNOTATION_CLIP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isClipDeleting: true,
    },
  })

  const response = yield call(deleteAnnotationClip, payload)
  if (response && response.deleteVideoAnnotationClip.status) {
    notification.success({
      message: response.deleteVideoAnnotationClip.message,
      duration: 1,
    })
    const { annotatedClips, selectedClip } = yield select(state => state.annotationClips)
    yield put({
      type: actions.SET_STATE,
      payload: {
        annotatedClips: annotatedClips.map(item => {
          if (item.id === selectedClip.id) {
            return { ...item, isDeleted: true }
          }
          return item
        }),
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isClipDeleting: false,
    },
  })
}

export function* UPDATE_SUB_CLASS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassSaving: true,
    },
  })

  const { role, annotationStaff } = yield select(state => state.user)
  const { selectedTab, selectedClip: annotatorClip } = yield select(
    state => state.annotationForStaff,
  )
  const { annotatedClips, selectedClip } = yield select(state => state.annotationClips)
  const { selectedClip: dashboardClip } = yield select(state => state.annotation)
  if (
    role === USER_TYPES.SuperUser ||
    (role === USER_TYPES.AnnotationStaff &&
      annotationStaff &&
      annotationStaff.isVerifier &&
      selectedTab === 'verifier')
  ) {
    const response = yield call(updateAnnotationSubclass, payload)
    if (response && response.updateAnnotation.status) {
      notification.success({
        message: response.updateAnnotation.message,
        duration: 1,
      })

      const clip = selectedClip || dashboardClip

      yield put({
        type: actions.SET_STATE,
        payload: {
          annotatedClips: annotatedClips.map(item => {
            if (item.id === clip.id) {
              return { ...response.updateAnnotation.clipDetails }
            }
            return item
          }),
          updateClassModal: false,
          selectedClip: { ...response.updateAnnotation.clipDetails },
          isAddClipDrawer: false,
          isEditingClip: false,
          playDrawerWidth: PLAY_DRAWER.largeDrawer,
        },
      })
      if (role === USER_TYPES.SuperUser) {
        yield put({
          type: ANNOTATION.REPLACE_ANNOTATION_CLIP,
          payload: {
            data: response.updateAnnotation.clipDetails,
          },
        })
      }
    }
  } else if (
    role === USER_TYPES.AnnotationStaff &&
    annotationStaff &&
    annotationStaff.isAnnotator &&
    selectedTab === 'annotator'
  ) {
    const response = yield call(createUpdateAnnotationByAnnotator, payload)
    if (response) {
      notification.success({
        message: 'Annotation Successfully updated!!',
        duration: 1,
      })
      const { annotationList } = response.createAnnotationByAnnotator
      const clip = selectedClip || annotatorClip

      const updatedAnnotation = annotationList.find(item => item.id === clip.id)

      if (updatedAnnotation) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            annotatedClips: annotatedClips.map(item => {
              if (item.id === clip.id) {
                return { ...updatedAnnotation }
              }
              return item
            }),
            updateClassModal: false,
            selectedClip: { ...updatedAnnotation },
            isAddClipDrawer: false,
            isEditingClip: false,
            playDrawerWidth: PLAY_DRAWER.largeDrawer,
          },
        })
      }
      yield put({
        type: ANNOTATION_FOR_STAFF.APPEND_ANNOTATOR_ANNOTATION,
        payload: {
          data: response.createAnnotationByAnnotator.annotationList,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassSaving: false,
    },
  })
}

export function* GET_VIDEOS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoLoading: true,
    },
  })

  const response = yield call(getVideos, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        videosList: response.getVideos.edges.map(({ node }) => ({ ...node })),
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isVideoLoading: false,
    },
  })
}

export function* CREATE_ANNOTATION_CLIP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationSaving: true,
    },
  })
  const { role, annotationStaff } = yield select(state => state.user)
  const { selectedTab } = yield select(state => state.annotationForStaff)

  if (
    role === USER_TYPES.SuperUser ||
    (role === USER_TYPES.AnnotationStaff &&
      annotationStaff &&
      annotationStaff.isVerifier &&
      selectedTab === 'verifier')
  ) {
    const response = yield call(createAnnotation, payload)
    if (response) {
      notification.success({
        message: 'Annotation Successfully created!!',
        duration: 1,
      })
      const listLength = response.createAnnotation.annotationList.length
      yield put({
        type: actions.APPEND_ANNOTATION_CLIP,
        payload: {
          data: response.createAnnotation.annotationList[listLength - 1],
        },
      })
      if (role === USER_TYPES.SuperUser) {
        yield put({
          type: ANNOTATION.APPEND_ANNOTATION_CLIP,
          payload: {
            data: response.createAnnotation.annotationList[listLength - 1],
          },
        })
      }
    }
  } else if (
    role === USER_TYPES.AnnotationStaff &&
    annotationStaff &&
    annotationStaff.isAnnotator &&
    selectedTab === 'annotator'
  ) {
    const response = yield call(createUpdateAnnotationByAnnotator, payload)
    if (response) {
      notification.success({
        message: 'Annotation Successfully created!!',
        duration: 1,
      })
      const listLength = response.createAnnotationByAnnotator.annotationList.length
      yield put({
        type: actions.APPEND_ANNOTATION_CLIP,
        payload: {
          data: response.createAnnotationByAnnotator.annotationList[listLength - 1],
        },
      })
      yield put({
        type: ANNOTATION_FOR_STAFF.APPEND_ANNOTATOR_ANNOTATION,
        payload: {
          data: response.createAnnotationByAnnotator.annotationList,
        },
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationSaving: false,
    },
  })
}

export function* ASSIGN_BULK_CLIPS_TO_VERIFIER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationAssignLoading: true,
    },
  })

  const { counter } = yield select(state => state.annotationClips)

  const response = yield call(assignBulkVideoClipsToVerifier, payload)
  if (
    response &&
    response.assignVideoClipsToVerifier &&
    response.assignVideoClipsToVerifier.status
  ) {
    notification.success({
      message: response.assignVideoClipsToVerifier.message,
      duration: 1,
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        isAnnotationAssignLoading: false,
        clipAssignDrawer: false,
        counter: counter + 1,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationAssignLoading: false,
    },
  })
}

export function* ASSIGN_SELECTED_CLIPS_TO_VERIFIER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationAssignLoading: true,
    },
  })

  const { counter } = yield select(state => state.annotationClips)

  const response = yield call(assignSelectedClipsToVerifier, payload)
  if (
    response &&
    response.assignSelectedClipsToVerifier &&
    response.assignSelectedClipsToVerifier.status
  ) {
    notification.success({
      message: response.assignSelectedClipsToVerifier.message,
      duration: 1,
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        clipAssignDrawer: false,
        counter: counter + 1,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationAssignLoading: false,
    },
  })
}

export function* MASS_STATUS_UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isMassStatusSaving: true,
    },
  })

  const response = yield call(massStatusUpdate, payload)
  if (
    response &&
    response.updateMassVideoClipsStatus &&
    response.updateMassVideoClipsStatus.status
  ) {
    const { annotatedClips } = yield select(state => state.annotationClips)

    notification.success({
      message: response.updateMassVideoClipsStatus.message,
      duration: 1,
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        annotatedClips: annotatedClips.map(item => {
          return { ...item, annotationStatus: getAnnotationStatusFromValue(payload.newStatus) }
        }),
        isMassStatusUpdateModal: false,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isMassStatusSaving: false,
    },
  })
}

export function* SELECTED_CLIP_STATUS_UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isMassStatusSaving: true,
    },
  })

  const response = yield call(selectedClipStatusUpdate, payload)
  if (response && response.updateSelectedClipsStatus && response.updateSelectedClipsStatus.status) {
    notification.success({
      message: response.updateSelectedClipsStatus.message,
    })

    yield put({
      type: actions.REPLACE_SELECTED_CLIP_STATUS,
      payload: {
        clipIDs: payload.clipIDs,
        status: payload.newStatus,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isMassStatusSaving: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_VIDEO_CLASS, GET_VIDEO_CLASS),
    takeLatest(actions.GET_VIDEO_ANNOTATIONS, GET_VIDEO_ANNOTATIONS),
    takeLatest(actions.GET_STAFFS, GET_STAFFS),
    takeLatest(actions.SAVE_DESCRIPTION, SAVE_DESCRIPTION),
    takeLatest(actions.VERIFIED_TO_PENDING, VERIFIED_TO_PENDING),

    takeLatest(actions.DELETE_ANNOTATION_CLIP, DELETE_ANNOTATION_CLIP),
    takeLatest(actions.UPDATE_SUB_CLASS, UPDATE_SUB_CLASS),
    takeLatest(actions.GET_VIDEOS, GET_VIDEOS),

    takeLatest(actions.CREATE_ANNOTATION_CLIP, CREATE_ANNOTATION_CLIP),
    takeEvery(actions.ASSIGN_BULK_CLIPS_TO_VERIFIER, ASSIGN_BULK_CLIPS_TO_VERIFIER),
    takeEvery(actions.ASSIGN_SELECTED_CLIPS_TO_VERIFIER, ASSIGN_SELECTED_CLIPS_TO_VERIFIER),

    takeEvery(actions.MASS_STATUS_UPDATE, MASS_STATUS_UPDATE),
    takeEvery(actions.SELECTED_CLIP_STATUS_UPDATE, SELECTED_CLIP_STATUS_UPDATE),
  ])
}
