/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function generateAnvil(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation generateAnvil($pk: ID!) {
          generateAnvil(input: { pk: $pk }) {
            url
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function deleteVideoAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteVideoAnnotation($pk: ID!, $deleteVideo: Boolean) {
          deleteVideoAnnotation(input: { pk: $pk, deleteVideo: $deleteVideo }) {
            msg
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotatedVideos(payload) {
  return apolloClient
    .query({
      query: gql`
        query getVideos(
          $offset: Int
          $first: Int
          $name: String
          $videoClasses: [ID]
          $userCodes: [ID]
          $isAnnotationExist: Boolean
          $annotator: ID
          $status: String
        ) {
          getVideos(
            first: $first
            offset: $offset
            name_Icontains: $name
            videoClasses: $videoClasses
            userCodes: $userCodes
            isAnnotationExist: $isAnnotationExist
            annotator: $annotator
            status: $status
          ) {
            totalCount
            edges {
              node {
                id
                name
                url
                createdAt
                status
                isAnnotationExist
                student {
                  id
                  enrolmentCode
                  age
                  diagnosis {
                    id
                    name
                  }
                }
                annotator {
                  id
                  firstname
                  lastname
                  email
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createVideoSubClass(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createVideoSubClass(
          $videoClassId: ID!
          $name: ID!
          $stimulus: [String]
          $response: [String]
          $feature: [String]
          $category: [String]
        ) {
          createVideoSubClass(
            input: {
              videoClassId: $videoClassId
              name: $name
              stimulus: $stimulus
              feature: $feature
              response: $response
              category: $category
            }
          ) {
            videoSubClass {
              id
              videoclass {
                id
                name
              }
              name
              feature {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              stimulus {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              response {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoById(payload) {
  return apolloClient
    .query({
      query: gql`
        query VideoObject($id: ID!) {
          videoUrl(id: $id) {
            id
            name
            createdAt
            url
            signedUrl
            status
            student {
              id
            }
            transcriptfilesSet {
              edges {
                node {
                  id
                }
              }
            }
            annotator {
              id
              firstname
              lastname
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createVideoAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation AnnotationVideo($student: ID!, $url: String!, $name: String) {
          createVideoAnnotation(input: { learner: $student, url: $url, name: $name }) {
            video {
              id
              url
              name
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getStudents() {
  return apolloClient
    .query({
      query: gql`
        query {
          getStudentInfo {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAWSVideoList(payload) {
  return apolloClient
    .query({
      query: gql`
        query getVideos($offset: Int, $name: String, $first: Int, $annotator: ID, $status: String) {
          getVideos(
            first: $first
            name_Icontains: $name
            annotator: $annotator
            status: $status
            offset: $offset
          ) {
            totalCount
            edges {
              node {
                id
                name
                status
                createdAt
                duration
                annotator {
                  id
                  firstname
                  lastname
                }
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoClip(payload) {
  return apolloClient
    .query({
      query: gql`
        query videoClipNew(
          $offset: Int
          $videoId: ID!
          $first: Int
          $videoClasses: [ID]
          $staffType: String
        ) {
          videoClipNew(
            videoId: $videoId
            first: $first
            offset: $offset
            videoClasses: $videoClasses
            staffType: $staffType
          ) {
            totalCount
            edges {
              node {
                id
                startTime
                endTime
                subclass {
                  videoclass {
                    id
                    name
                  }
                  id
                  name
                }
                feature {
                  id
                  name
                }
                stimuli {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                behaviours {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                emotions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                jointAttentions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                category {
                  id
                  name
                }
                response {
                  id
                  name
                }
                note
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotateInit(payload) {
  return apolloClient
    .query({
      query: gql`
        query AnnotateInit($subId: ID) {
          vfeature(subId: $subId) {
            id
            name
          }
          vstimulus(subId: $subId) {
            id
            name
          }
          vcategory(subId: $subId) {
            id
            name
          }
          vresponse(subId: $subId) {
            id
            name
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

// verifier and admin
export async function createAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createAnnotation(
          $videoId: ID!
          $subId: ID
          $startTime: Float
          $endTime: Float
          $selfeature: ID
          $selstimulus: ID
          $selcategory: ID
          $selresponse: ID
          $selcomment: String
          $clipId: ID
        ) {
          createAnnotation(
            input: {
              videoId: $videoId
              subId: $subId
              startTime: $startTime
              endTime: $endTime
              feature: $selfeature
              stimulus: $selstimulus
              category: $selcategory
              response: $selresponse
              note: $selcomment
              clipId: $clipId
            }
          ) {
            annotationList {
              id
              startTime
              endTime
              note
              subclass {
                id
                name
              }
              feature {
                id
                name
              }
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              behaviours {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              emotions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jointAttentions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function deleteAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteAnnotation($pk: ID) {
          deleteAnnotation(input: { pk: $pk }) {
            status
            msg
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoClass() {
  return apolloClient
    .query({
      query: gql`
        query {
          videoClass {
            id
            name
            videosubclassSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnvilData(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation previewAnvilFile($videoId: ID!) {
          previewAnvilFile(input: { pk: $videoId, staffType: "verifier" }) {
            message
            status
            anvilData
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function generateAnvilBackground(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation generateAnvil($videoId: ID!) {
          generateAnvil(input: { pk: $videoId }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function updateVideo(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateVideo($videoId: ID!, $annotationStaff: ID!) {
          updateVideo(input: { videoId: $videoId, annotatorStaff: $annotationStaff }) {
            message
            status
            video {
              id
              name
              annotator {
                id
                firstname
                lastname
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotationStaffs() {
  return apolloClient
    .query({
      query: gql`
        query {
          getAnnotationStaff {
            edges {
              node {
                id
                firstname
                lastname
                isAnnotator
                isVerifier
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getInterrateReport(payload) {
  return apolloClient
    .query({
      query: gql`
        query getInterrateAggrementSimilarity($videoId: ID!) {
          getInterrateAggrementSimilarity(videoId: $videoId)
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoClipsByClass(payload) {
  return apolloClient
    .query({
      query: gql`
        query videoClipsByVideoId($videoId: ID!, $videoClass: ID!) {
          videoClipsByVideoId(videoId: $videoId, videoClass: $videoClass) {
            annotatorClips {
              id
              startTime
              endTime
              note
              annotationStatus
              subclass {
                id
                name
                videoclass {
                  id
                  name
                }
              }
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              behaviours {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              emotions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jointAttentions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
              transStimulus
              target {
                id
                targetName
              }
              transResponse
              errorCorrectionProcdure
              promptUsed {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              clinicalSuggestion
              socialOrientation {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              socialOrientation2 {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function updateVideoSatus(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateVideoStatusByAdmin($videoId: ID!, $status: String!) {
          updateVideoStatusByAdmin(input: { videoId: $videoId, status: $status }) {
            status
            message
            video {
              id
              name
              status
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getClassSubClassInfo() {
  return apolloClient
    .query({
      query: gql`
        {
          videoClass {
            id
            name
            videosubclassSet {
              edges {
                node {
                  id
                  name
                  feature {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  stimulus {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  stimuli {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  category {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  response {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function addDataInSubclass(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation addDataInClass(
          $classId: ID!
          $subClassId: ID!
          $stimulus: [String]
          $response: [String]
          $category: [String]
          $feature: [String]
        ) {
          addDataInClass(
            input: {
              classId: $classId
              subclassId: $subClassId
              stimulus: $stimulus
              response: $response
              category: $category
              feature: $feature
            }
          ) {
            status
            message
            videoClass {
              id
              name
              videosubclassSet {
                edges {
                  node {
                    id
                    name
                    feature {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    stimulus {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    stimuli {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    category {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    response {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getMoreDropdown() {
  return apolloClient
    .query({
      query: gql`
        {
          getBehaviours {
            edges {
              node {
                id
                name
              }
            }
          }
          getEmotions {
            edges {
              node {
                id
                name
              }
            }
          }
          getJointAttentions {
            edges {
              node {
                id
                name
              }
            }
          }
          getSocialOrientation {
            edges {
              node {
                id
                name
              }
            }
          }
          getPrompts {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function addMoreData(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation addEmotionBehaviourJointAttention(
          $behaviours: [String]
          $jointAttentions: [String]
          $emotions: [String]
          $socialOrientation: [String]
        ) {
          addEmotionBehaviourJointAttention(
            input: {
              behaviours: $behaviours
              jointAttentions: $jointAttentions
              emotions: $emotions
              socialOrientation: $socialOrientation
            }
          ) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getStudentTargets(payload) {
  return apolloClient
    .query({
      query: gql`
        query getTargetByStudentId($studentId: ID!) {
          getTargetByStudentId(studentId: $studentId) {
            edges {
              node {
                id
                targetName
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
