/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-const */
import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import {
  assignVideoToAnnotator,
  assignVideoToVerifier,
  createAnnotationStaff,
  getAnnotationStaffs,
  getAssignVideosAsAnnotator,
  getAssignVideosAsVerifier,
  getUnassignedVideosBySuperadmin,
  updateAnnotationStaff,
} from 'services/annotationStaffs'

import { notification } from 'antd'
import ANNOTATION_STAFFS from './actions'

export function* GET_ANNOTATION_STAFFS({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isStaffsLoading: true,
    },
  })

  const response = yield call(getAnnotationStaffs, { ...payload })
  if (response) {
    yield put({
      type: ANNOTATION_STAFFS.SET_STATE,
      payload: {
        annotationStaffs: response.getAnnotationStaff.edges,
        staffCount: response.getAnnotationStaff.totalCount,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isStaffsLoading: false,
    },
  })
}

export function* CREATE_ANNOTATION_STAFF({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isStaffSaving: true,
    },
  })

  const response = yield call(createAnnotationStaff, payload)
  if (response && response.createAnnotationStaff.status) {
    notification.success({
      message: response.createAnnotationStaff.message,
    })
    yield put({
      type: ANNOTATION_STAFFS.APPEND_ANNOTATION_STAFF,
      payload: {
        data: response.createAnnotationStaff.annotationStaff,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isStaffSaving: false,
    },
  })
}
export function* UPDATE_ANNOTATION_STAFF({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isStaffSaving: true,
    },
  })

  const response = yield call(updateAnnotationStaff, payload)
  if (response && response.updateAnnotationStaff.status) {
    notification.success({
      message: response.updateAnnotationStaff.message,
    })
    yield put({
      type: ANNOTATION_STAFFS.REPLACE_ANNOTATION_STAFF,
      payload: {
        data: response.updateAnnotationStaff.annotationStaff,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isStaffSaving: false,
    },
  })
}

export function* GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isUnAssignedVideosLoading: true,
    },
  })

  const response = yield call(getUnassignedVideosBySuperadmin, payload)
  if (response && response.getUnassignedVideosBySuperadmin) {
    yield put({
      type: ANNOTATION_STAFFS.SET_STATE,
      payload: {
        unAssignedVideos: response.getUnassignedVideosBySuperadmin.edges,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isUnAssignedVideosLoading: false,
    },
  })
}

export function* GET_ASSIGNED_VIDEOS_AS_ANNOTATOR({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isAnnotatorVideosLoading: true,
    },
  })

  const response = yield call(getAssignVideosAsAnnotator, payload)
  if (response && response.getAssignedVideosBySuperadmin) {
    yield put({
      type: ANNOTATION_STAFFS.SET_STATE,
      payload: {
        allAssignedVideosAsAnnotator: response.getAssignedVideosBySuperadmin.edges,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isAnnotatorVideosLoading: false,
    },
  })
}

export function* GET_ASSIGNED_VIDEOS_AS_VERIFIER({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isVerifierVideoLoading: true,
    },
  })

  const response = yield call(getAssignVideosAsVerifier, payload)
  if (response && response.getAssignedVideosBySuperadmin) {
    yield put({
      type: ANNOTATION_STAFFS.SET_STATE,
      payload: {
        allAssignedVideosAsVerifier: response.getAssignedVideosBySuperadmin.edges,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isVerifierVideoLoading: false,
    },
  })
}

export function* ASSIGN_VIDEO_TO_ANNOTATOR({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isAnnotatorVideoSaving: true,
    },
  })

  const response = yield call(assignVideoToAnnotator, payload)
  if (response && response.assignVideoToAnnotatorAndVerifier.status) {
    notification.success({
      message: response.assignVideoToAnnotatorAndVerifier.message,
    })
    yield put({
      type: ANNOTATION_STAFFS.APPEND_ASSIGNED_ANNOTATE_VIDEO,
      payload: {
        data: response.assignVideoToAnnotatorAndVerifier.assignedVideos,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isAnnotatorVideoSaving: false,
    },
  })
}

export function* ASSIGN_VIDEO_TO_VERIFIER({ payload }) {
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isVerifyVideoSaving: true,
    },
  })

  const response = yield call(assignVideoToVerifier, payload)
  if (response && response.assignVideoToAnnotatorAndVerifier.status) {
    notification.success({
      message: response.assignVideoToAnnotatorAndVerifier.message,
    })
    yield put({
      type: ANNOTATION_STAFFS.APPEND_ASSIGNED_VERIFY_VIDEO,
      payload: {
        data: response.assignVideoToAnnotatorAndVerifier.assignedVideos,
      },
    })
  }
  yield put({
    type: ANNOTATION_STAFFS.SET_STATE,
    payload: {
      isVerifyVideoSaving: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(ANNOTATION_STAFFS.GET_ANNOTATION_STAFFS, GET_ANNOTATION_STAFFS),
    takeLatest(ANNOTATION_STAFFS.CREATE_ANNOTATION_STAFF, CREATE_ANNOTATION_STAFF),
    takeLatest(ANNOTATION_STAFFS.UPDATE_ANNOTATION_STAFF, UPDATE_ANNOTATION_STAFF),
    takeLatest(
      ANNOTATION_STAFFS.GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN,
      GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN,
    ),
    takeLatest(
      ANNOTATION_STAFFS.GET_ASSIGNED_VIDEOS_AS_ANNOTATOR,
      GET_ASSIGNED_VIDEOS_AS_ANNOTATOR,
    ),
    takeLatest(ANNOTATION_STAFFS.GET_ASSIGNED_VIDEOS_AS_VERIFIER, GET_ASSIGNED_VIDEOS_AS_VERIFIER),
    takeLatest(ANNOTATION_STAFFS.ASSIGN_VIDEO_TO_ANNOTATOR, ASSIGN_VIDEO_TO_ANNOTATOR),
    takeLatest(ANNOTATION_STAFFS.ASSIGN_VIDEO_TO_VERIFIER, ASSIGN_VIDEO_TO_VERIFIER),
  ])
}
