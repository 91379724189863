import moment from 'moment'
import actions from './actions'

const initialState = {
  loading: false,
  VideoCounts: null,

  // Staff report variables
  ReportOn: 'days',
  ReportFor: 'annotator',
  SelectedDates: [moment().subtract(7, 'days'), moment()],
  CalculatedWeeks: [],
  CalculatedMonths: [],
  StaffData: [],

  // Classes report variables
  ClassesData: [],
  SelectedStatus: 'under_verification',
  TotalCount: 0,
  ClassData: [],
  isSubClassSaving: false,
  isAddSubClassDataDrawer: false,

  // Annotations Counts report
  SelectedClass: null,
  ClassCountData: [],
  SelectedClassSubClass: [],
  TotalValues: null,

  // create class
  isClassSaving: false,
  isAddClassModal: false,

  isAddMoreDataDrawer: false,
}

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
