const ANNOTATION_LEARNERS = {
  SET_STATE: 'annotationLearners/SET_STATE',
  GET_ANNOTATION_LEARNERS: 'annotationLearners/GET_ANNOTATION_LEARNERS',
  CREATE_UPDATE_ANNOTATION_LEARNER: 'annotationLearners/CREATE_ANNOTATION_LEARNER',
  REPLACE_ANNOTATION_LEARNER: 'annotationLearners/REPLACE_ANNOTATION_LEARNER',
  CREATE_DIAGNOSIS: 'annotationLearners/CREATE_DIAGNOSIS',
  APPEND_DIAGNOSIS: 'annotationLearners/APPEND_DIAGNOSIS',
  GET_DIAGNOSIS: 'annotationLearners/GET_DIAGNOSIS',
}

export default ANNOTATION_LEARNERS
