const actions = {
  SET_STATE: 'annotationClips/SET_STATE',
  GET_VIDEO_CLASS: 'annotationClips/GET_VIDEO_CLASS',
  GET_VIDEO_ANNOTATIONS: 'annotationClips/GET_VIDEO_ANNOTATIONS',
  GET_STAFFS: 'annotationClips/GET_STAFFS',
  SAVE_DESCRIPTION: 'annotationClips/SAVE_DESCRIPTION',
  DELETE_ANNOTATION_CLIP: 'annotationClips/DELETE_ANNOTATION_CLIP',
  UPDATE_SUB_CLASS: 'annotationClips/UPDATE_SUB_CLASS',
  REPLACE_ANNOTATION_CLIP: 'annotationClips/REPLACE_ANNOTATION_CLIP',

  GET_VIDEOS: 'annotationClips/GET_VIDEOS',
  CREATE_ANNOTATION_CLIP: 'annotationClips/CREATE_ANNOTATION_CLIP',
  APPEND_ANNOTATION_CLIP: 'annotationClips/APPEND_ANNOTATION_CLIP',

  ASSIGN_BULK_CLIPS_TO_VERIFIER: 'annotationClips/ASSIGN_BULK_CLIPS_TO_VERIFIER',
  ASSIGN_SELECTED_CLIPS_TO_VERIFIER: 'annotationClips/ASSIGN_SELECTED_CLIPS_TO_VERIFIER',

  POP_CLIP: 'annotationClips/POP_CLIP',
  VERIFIED_TO_PENDING: 'annotationClips/VERIFIED_TO_PENDING',

  MASS_STATUS_UPDATE: 'annotationClips/MASS_STATUS_UPDATE',
  SELECTED_CLIP_STATUS_UPDATE: 'annotationClips/SELECTED_CLIP_STATUS_UPDATE',
  REPLACE_SELECTED_CLIP_STATUS: 'annotationClips/REPLACE_SELECTED_CLIP_STATUS',
}

export default actions
