/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function assignBulkVideoClipsToVerifier(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignVideoClipsToVerifier(
          $assignDataList: [ClipsCountAndStaffInfoInput]
          $videoClass: ID
          $status: String
          $clipAnnotatedBy: ID
          $clipVerifiedBy: ID
          $annotatedOn: Date
          $verifiedOn: Date
          $descriptionEmpty: Boolean
          $videoName: String
          $dataOrderBy: String
          $subclassId: ID
          $verifier: ID
          $isUnassignedVideos: Boolean
          $updatedOn: Date
        ) {
          assignVideoClipsToVerifier(
            input: {
              assignDataList: $assignDataList
              videoClass: $videoClass
              status: $status
              clipAnnotatedBy: $clipAnnotatedBy
              clipVerifiedBy: $clipVerifiedBy
              annotatedOn: $annotatedOn
              verifiedOn: $verifiedOn
              descriptionEmpty: $descriptionEmpty
              videoName: $videoName
              dataOrderBy: $dataOrderBy
              subclassId: $subclassId
              verifier: $verifier
              isUnassignedVideos: $isUnassignedVideos
              updatedOn: $updatedOn
            }
          ) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function assignSelectedClipsToVerifier(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignSelectedClipsToVerifier($clipsIds: [ID]!, $staffId: ID!) {
          assignSelectedClipsToVerifier(input: { staffId: $staffId, clipsIds: $clipsIds }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoClass() {
  return apolloClient
    .query({
      query: gql`
        query {
          videoClass {
            id
            name
            videosubclassSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotationStaff() {
  return apolloClient
    .query({
      query: gql`
        query {
          getAnnotationStaff {
            edges {
              node {
                id
                firstname
                lastname
                email
                isAnnotator
                isVerifier
                user {
                  id
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoAnnotations(payload) {
  return apolloClient
    .query({
      query: gql`
        query videoClipReportNew(
          $videoClass: ID
          $status: String
          $annotatedBy: ID
          $annotatedOn: Date
          $verifiedBy: ID
          $verifiedOn: Date
          $updatedOn: Date
          $descriptionEmpty: Boolean
          $videoName: String
          $subclass: ID
          $isUnassignedVideos: Boolean
          $verifier: ID
          $offset: Int
          $pageSize: Int
          $isStimulus: Boolean
        ) {
          videoClipReportNew(
            offset: $offset
            pageSize: $pageSize
            status: $status
            videoClass: $videoClass
            subclass: $subclass
            annotatedOn: $annotatedOn
            verifiedOn: $verifiedOn
            updatedOn: $updatedOn
            clipVerifiedBy: $verifiedBy
            clipAnnotatedBy: $annotatedBy
            descriptionEmpty: $descriptionEmpty
            videoName: $videoName
            isUnassignedVideos: $isUnassignedVideos
            verifier: $verifier
            isStimulus: $isStimulus
          ) {
            totalCount
            edges {
              node {
                id
                video {
                  id
                  name
                  status
                  student {
                    id
                  }
                }
                annotationStatus
                subclass {
                  id
                  name
                  videoclass {
                    id
                    name
                  }
                }
                startTime
                endTime
                stimuli {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                behaviours {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                emotions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                jointAttentions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                category {
                  id
                  name
                }
                transStimulus
                target {
                  id
                  targetName
                }
                transResponse
                errorCorrectionProcdure
                promptUsed {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                clinicalSuggestion
                socialOrientation {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                socialOrientation2 {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                transcriptData {
                  edges {
                    node {
                      id
                      lineNo
                      conversation
                      fromTime
                      toTime
                      speaker
                    }
                  }
                }
                annotatedAt
                verifiedAt
                annotatedBy {
                  id
                  firstName
                  lastName
                  isSuperuser
                  username
                }
                verifier {
                  id
                  firstname
                  lastname
                }
                verifiedBy {
                  id
                  firstName
                  lastName
                  isSuperuser
                  username
                }
                updatedBy {
                  id
                  isSuperuser
                  username
                  firstName
                  lastName
                }
                updatedAt
                note
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function saveAnnotationDescription(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation UpdateVideoAnnotationNote($objectId: ID!, $description: String!) {
          updateVideoAnnotationNote(input: { videoClipId: $objectId, note: $description }) {
            message
            status
            videoClip {
              id
              video {
                id
                name
              }

              note
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function deleteAnnotationClip(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteVideoAnnotationClip($videoClipId: ID!, $videoId: ID!) {
          deleteVideoAnnotationClip(input: { videoClipId: $videoClipId, videoId: $videoId }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function VerifiedToPending(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation VerifiedToPending($videoClipId: ID!, $status: String!) {
          updateVideoClipStatusByAdmin(input: { videoClipId: $videoClipId, status: $status }) {
            status
            message
            videoClip {
              id
              annotationStatus
              verifiedAt
              verifiedBy {
                id
                firstName
                lastName
                isSuperuser
                username
              }
              subclass {
                id
                name
                videoclass {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
// verifier and admin
export async function updateAnnotationSubclass(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateAnnotation(
          $videoId: ID!
          $subClass: ID
          $clipId: ID!
          $annotationStatus: String
          $description: String
          $stimulus: [ID]
          $behaviours: [ID]
          $emotions: [ID]
          $jointAttentions: [ID]
          $startTime: Float
          $endTime: Float
          $transStimulus: String
          $target: ID
          $transResponse: String
          $errorCorrectionProcdure: String
          $promptUsed: [ID]
          $clinicalSuggestion: String
          $socialOrientation: [ID]
          $socialOrientation2: [ID]
          $category: ID
        ) {
          updateAnnotation(
            input: {
              videoId: $videoId
              subId: $subClass
              clipId: $clipId
              annotationStatus: $annotationStatus
              stimuliIds: $stimulus
              behaviourIds: $behaviours
              emotionIds: $emotions
              jointAttentionIds: $jointAttentions
              note: $description
              startTime: $startTime
              endTime: $endTime
              transStimulus: $transStimulus
              target: $target
              category: $category
              transResponse: $transResponse
              errorCorrectionProcdure: $errorCorrectionProcdure
              promptUsed: $promptUsed
              clinicalSuggestion: $clinicalSuggestion
              socialOrientation: $socialOrientation
              socialOrientation2: $socialOrientation2
            }
          ) {
            message
            status
            clipDetails {
              id
              video {
                id
                name
                status
                student {
                  id
                }
              }
              annotationStatus
              subclass {
                id
                name
                videoclass {
                  id
                  name
                }
              }
              startTime
              endTime
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              behaviours {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              emotions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jointAttentions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
              transStimulus
              target {
                id
                targetName
              }
              transResponse
              errorCorrectionProcdure
              promptUsed {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              clinicalSuggestion
              socialOrientation {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              socialOrientation2 {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              transcriptData {
                edges {
                  node {
                    id
                    lineNo
                    conversation
                    fromTime
                    toTime
                    speaker
                  }
                }
              }
              annotatedAt
              verifiedAt
              annotatedBy {
                id
                firstName
                lastName
                isSuperuser
                username
              }
              verifier {
                id
                firstname
                lastname
              }
              verifiedBy {
                id
                firstName
                lastName
                isSuperuser
                username
              }
              updatedBy {
                id
                isSuperuser
                username
                firstName
                lastName
              }
              updatedAt
              note
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoClipReportByVerifier(payload) {
  return apolloClient
    .query({
      query: gql`
        query videoClipReportByVerifier(
          $videoClass: ID
          $status: String
          $annotatedBy: ID
          $annotatedOn: Date
          $descriptionEmpty: Boolean
          $videoName: String
          $subclass: ID
          $verifiedOn: Date
          $updatedOn: Date
          $offset: Int
          $pageSize: Int
          $isStimulus: Boolean
        ) {
          videoClipReportByVerifier(
            status: $status
            offset: $offset
            pageSize: $pageSize
            subclass: $subclass
            videoClass: $videoClass
            annotatedOn: $annotatedOn
            clipAnnotatedBy: $annotatedBy
            descriptionEmpty: $descriptionEmpty
            videoName: $videoName
            verifiedOn: $verifiedOn
            updatedOn: $updatedOn
            isStimulus: $isStimulus
          ) {
            totalCount
            edges {
              node {
                id
                video {
                  id
                  name
                  status
                  student {
                    id
                  }
                }
                annotationStatus
                subclass {
                  id
                  name
                  videoclass {
                    id
                    name
                  }
                }
                startTime
                endTime
                stimuli {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                behaviours {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                emotions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                jointAttentions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                category {
                  id
                  name
                }
                transStimulus
                target {
                  id
                  targetName
                }
                transResponse
                errorCorrectionProcdure
                promptUsed {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                clinicalSuggestion
                socialOrientation {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                socialOrientation2 {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                transcriptData {
                  edges {
                    node {
                      id
                      lineNo
                      conversation
                      fromTime
                      toTime
                      speaker
                    }
                  }
                }

                annotatedAt
                verifiedAt
                annotatedBy {
                  id
                  firstName
                  lastName
                  isSuperuser
                  username
                }
                verifier {
                  id
                  firstname
                  lastname
                }
                verifiedBy {
                  id
                  firstName
                  lastName
                  isSuperuser
                  username
                }
                updatedBy {
                  id
                  isSuperuser
                  username
                  firstName
                  lastName
                }
                updatedAt
                note
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function getVideoClipReportByAnnotator(payload) {
  return apolloClient
    .query({
      query: gql`
        query videoClipReportByAnnotator(
          $offset: Int
          $pageSize: Int
          $videoClass: ID
          $status: String
          $annotatedOn: Date
          $descriptionEmpty: Boolean
          $videoName: String
          $subclass: ID
          $updatedOn: Date
          $isStimulus: Boolean
        ) {
          videoClipReportByAnnotator(
            offset: $offset
            pageSize: $pageSize
            status: $status
            subclass: $subclass
            videoClass: $videoClass
            annotatedOn: $annotatedOn
            descriptionEmpty: $descriptionEmpty
            videoName: $videoName
            updatedOn: $updatedOn
            isStimulus: $isStimulus
          ) {
            totalCount
            edges {
              node {
                id
                video {
                  id
                  name
                  status
                  student {
                    id
                  }
                }
                annotationStatus
                subclass {
                  id
                  name
                  videoclass {
                    id
                    name
                  }
                }
                startTime
                endTime
                stimuli {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                behaviours {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                emotions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                jointAttentions {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                category {
                  id
                  name
                }
                transStimulus
                target {
                  id
                  targetName
                }
                transResponse
                errorCorrectionProcdure
                promptUsed {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                clinicalSuggestion
                socialOrientation {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                socialOrientation2 {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                transcriptData {
                  edges {
                    node {
                      id
                      lineNo
                      conversation
                      fromTime
                      toTime
                      speaker
                    }
                  }
                }
                annotatedAt
                verifiedAt
                annotatedBy {
                  id
                  firstName
                  lastName
                  isSuperuser
                  username
                }
                verifier {
                  id
                  firstname
                  lastname
                }
                verifiedBy {
                  id
                  firstName
                  lastName
                  isSuperuser
                  username
                }
                updatedBy {
                  id
                  isSuperuser
                  username
                  firstName
                  lastName
                }
                updatedAt
                note
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createUpdateAnnotationByAnnotator(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createAnnotationByAnnotator(
          $videoId: ID!
          $subClass: ID
          $startTime: Float
          $endTime: Float
          $stimulus: [ID]
          $behaviours: [ID]
          $emotions: [ID]
          $jointAttentions: [ID]
          $description: String
          $clipId: ID
          $transStimulus: String
          $target: ID
          $transResponse: String
          $errorCorrectionProcdure: String
          $promptUsed: [ID]
          $clinicalSuggestion: String
          $socialOrientation: [ID]
          $socialOrientation2: [ID]
          $category: ID
        ) {
          createAnnotationByAnnotator(
            input: {
              videoId: $videoId
              subId: $subClass
              startTime: $startTime
              endTime: $endTime
              stimuliIds: $stimulus
              behaviourIds: $behaviours
              emotionIds: $emotions
              jointAttentionIds: $jointAttentions
              note: $description
              category: $category
              transStimulus: $transStimulus
              target: $target
              transResponse: $transResponse
              errorCorrectionProcdure: $errorCorrectionProcdure
              promptUsed: $promptUsed
              clinicalSuggestion: $clinicalSuggestion
              socialOrientation: $socialOrientation
              socialOrientation2: $socialOrientation2
              clipId: $clipId
            }
          ) {
            message
            status
            annotationList {
              id
              video {
                id
                name
                status
                student {
                  id
                }
              }
              annotationStatus
              subclass {
                id
                name
                videoclass {
                  id
                  name
                }
              }
              startTime
              endTime
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              behaviours {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              emotions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jointAttentions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
              transStimulus
              target {
                id
                targetName
              }
              transResponse
              errorCorrectionProcdure
              promptUsed {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              clinicalSuggestion
              socialOrientation {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              socialOrientation2 {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              transcriptData {
                edges {
                  node {
                    id
                    lineNo
                    conversation
                    fromTime
                    toTime
                    speaker
                  }
                }
              }
              annotatedAt
              verifiedAt
              annotatedBy {
                id
                firstName
                lastName
                isSuperuser
                username
              }
              verifier {
                id
                firstname
                lastname
              }
              verifiedBy {
                id
                firstName
                lastName
                isSuperuser
                username
              }
              updatedBy {
                id
                isSuperuser
                username
                firstName
                lastName
              }
              updatedAt
              note
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideos(payload) {
  return apolloClient
    .query({
      query: gql`
        query getVideos($name: String, $first: Int) {
          getVideos(first: $first, name_Icontains: $name) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createAnnotation(
          $videoId: ID!
          $subClass: ID
          $startTime: Float
          $endTime: Float
          $feature: ID
          $stimulus: [ID]
          $behaviours: [ID]
          $emotions: [ID]
          $jointAttentions: [ID]
          $category: ID
          $response: ID
          $description: String
          $transStimulus: String
          $target: ID
          $transResponse: String
          $errorCorrectionProcdure: String
          $promptUsed: [ID]
          $clinicalSuggestion: String
          $socialOrientation: [ID]
          $socialOrientation2: [ID]
        ) {
          createAnnotation(
            input: {
              videoId: $videoId
              subId: $subClass
              startTime: $startTime
              endTime: $endTime
              feature: $feature
              stimuliIds: $stimulus
              behaviourIds: $behaviours
              emotionIds: $emotions
              jointAttentionIds: $jointAttentions
              category: $category
              response: $response
              note: $description
              transStimulus: $transStimulus
              target: $target
              transResponse: $transResponse
              errorCorrectionProcdure: $errorCorrectionProcdure
              promptUsed: $promptUsed
              clinicalSuggestion: $clinicalSuggestion
              socialOrientation: $socialOrientation
              socialOrientation2: $socialOrientation2
            }
          ) {
            message
            status
            annotationList {
              id
              video {
                id
                name
                status
                student {
                  id
                }
              }
              annotationStatus
              subclass {
                id
                name
                videoclass {
                  id
                  name
                }
              }
              startTime
              endTime
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              behaviours {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              emotions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jointAttentions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
              transStimulus
              target {
                id
                targetName
              }
              transResponse
              errorCorrectionProcdure
              promptUsed {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              clinicalSuggestion
              socialOrientation {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              socialOrientation2 {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              transcriptData {
                edges {
                  node {
                    id
                    lineNo
                    conversation
                    fromTime
                    toTime
                    speaker
                  }
                }
              }
              annotatedAt
              verifiedAt
              annotatedBy {
                id
                firstName
                lastName
                isSuperuser
                username
              }
              verifier {
                id
                firstname
                lastname
              }
              verifiedBy {
                id
                firstName
                lastName
                isSuperuser
                username
              }
              updatedBy {
                id
                isSuperuser
                username
                firstName
                lastName
              }
              updatedAt
              note
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function massStatusUpdate(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateMassVideoClipsStatus(
          $videoClass: ID
          $status: String!
          $annotatedBy: ID
          $annotatedOn: Date
          $verifiedBy: ID
          $verifiedOn: Date
          $updatedOn: Date
          $descriptionEmpty: Boolean
          $videoName: String
          $subclass: ID
          $isUnassignedVideos: Boolean
          $verifier: ID
          $newStatus: String!
          $isStimulus: Boolean
        ) {
          updateMassVideoClipsStatus(
            input: {
              status: $status
              videoClass: $videoClass
              subclassId: $subclass
              annotatedOn: $annotatedOn
              verifiedOn: $verifiedOn
              updatedOn: $updatedOn
              clipVerifiedBy: $verifiedBy
              clipAnnotatedBy: $annotatedBy
              descriptionEmpty: $descriptionEmpty
              videoName: $videoName
              isUnassignedVideos: $isUnassignedVideos
              verifier: $verifier
              isStimulus: $isStimulus
              changeStatusTo: $newStatus
            }
          ) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function selectedClipStatusUpdate(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateSelectedClipsStatus($newStatus: String!, $clipIDs: [ID]!) {
          updateSelectedClipsStatus(input: { changeStatusTo: $newStatus, clipsIds: $clipIDs }) {
            message
            status
            clips {
              id
              annotationStatus
              verifiedAt
              verifiedBy {
                id
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
