import { PLAY_DRAWER, STEP_DRAWER } from 'assets/styles/globalStyles'
import { getAnnotationStatusFromValue } from 'utilities'
import actions from './actions'

const initialState = {
  subClassList: [],
  videoClass: [],
  isVideoClassLoading: false,

  isAnotatedClipsLoading: false,
  annotatedClips: [],
  prevPageNo: 1,
  videoClipsCount: 0,
  videoClipPageInfo: null,
  clipType: 'next',

  VideoStatus: [
    { name: 'Assigned', value: 'assigned' },
    { name: 'Re-Assigned', value: 're-assigned' },
    { name: 'Under Verification', value: 'under_verification' },
    { name: 'Under Re-Verification', value: 'under_re-verification' },
    { name: 'Verified', value: 'verified' },
  ],
  isStaffLoading: false,
  annotators: [],
  verifiers: [],
  isDescriptionSaving: false,
  isClipDeleting: false,
  selectedClip: null,
  updateClassModal: false,
  isSubClassSaving: false,

  isEditingClip: false,
  selectedVideoDuration: 0,

  // Add Clip
  isAddClipDrawer: false,
  isClipSaving: false,
  playDrawerWidth: PLAY_DRAWER.mediumDrawer,

  isStepDrawer: false,
  currentStep: 0,
  isVideoLoading: false,
  videosList: [],
  selectedVideoId: null,
  stepDrawerWidth: STEP_DRAWER.smallDrawer,
  isAnnotationSaving: false,

  // Assign annottaions states
  clipAssignDrawer: false,
  isAnnotationAssignLoading: false,
  counter: 0,
  isStatusSaving: false,
  isAnnotationStatusModal: false,

  isMassStatusUpdateModal: false,
  isMassStatusSaving: false,
}

export default function annotationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.APPEND_ANNOTATION_CLIP:
      return {
        ...state,
        annotatedClips: [{ ...action.payload.data, isNewlyAdded: true }, ...state.annotatedClips],
        isAddClipDrawer: false,
        playDrawerWidth: PLAY_DRAWER.largeDrawer,
        isStepDrawer: false,
        currentStep: 0,
        stepDrawerWidth: STEP_DRAWER.smallDrawer,
        videoClipsCount: state.videoClipsCount + 1,
      }

    case actions.POP_CLIP:
      return {
        ...state,
        selectedClip: null,
        annotatedClips: state.annotatedClips.filter(item => item.id !== action.payload.data.pk),
      }
    case actions.REPLACE_SELECTED_CLIP_STATUS:
      return {
        ...state,
        annotatedClips: state.annotatedClips.map(item => {
          if (action.payload.clipIDs.includes(item.id)) {
            return {
              ...item,
              annotationStatus: getAnnotationStatusFromValue(action.payload.status),
            }
          }
          return { ...item }
        }),
        isMassStatusUpdateModal: false,
      }
    default:
      return state
  }
}
