/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-const */
import { all, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import ANNOTATION_FOR_STAFF from './actions'
import {
  createUpdateAnnotationByAnnotator,
  createUpdateAnnotationByVerifier,
  deleteAnnotation,
  generateAnvilBackground,
  getAnnotateInit,
  getAnvilData,
  getAssignedVideos,
  getVideoById,
  getVideoClass,
  getVideoClipsByClass,
  submitVideoAnnotationByAnnotator,
  submitVideoAnnotationByVerifier,
} from '../../services/annotationForStaff'
import ANNOTATION_CLIP from '../AnnotationClips/actions'

export function* GET_ASSIGNED_VIDEOS({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAssignedVideosLoading: true,
    },
  })

  const response = yield call(getAssignedVideos, {
    ...payload,
  })
  if (response) {
    yield put({
      type: ANNOTATION_FOR_STAFF.SET_STATE,
      payload: {
        assignedVideos: response.getAssignedVideosByStaff.edges.map(({ node }) => ({ ...node })),

        videosCount: response.getAssignedVideosByStaff.totalCount,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAssignedVideosLoading: false,
    },
  })
}

export function* GET_VIDEO_BY_ID({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVideoDetailsLoading: true,
    },
  })
  const response = yield call(getVideoById, payload)
  if (response) {
    const { selectedTab } = yield select(state => state.annotationForStaff)
    yield put({
      type: ANNOTATION_FOR_STAFF.SET_STATE,
      payload: {
        videoDetails: response.videoUrl,
        selectedAnnotationVideo: selectedTab === 'annotator' ? response.videoUrl : null,
        selectedVerifyVideo: selectedTab === 'verifier' ? response.videoUrl : null,
        selectedClip: null,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVideoDetailsLoading: false,
    },
  })
}

export function* GET_VIDEO_CLASS() {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVideoClassLoading: true,
    },
  })
  const response = yield call(getVideoClass)
  if (response) {
    yield put({
      type: ANNOTATION_FOR_STAFF.SET_STATE,
      payload: {
        videoClass: response.videoClass,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVideoClassLoading: false,
    },
  })
}

export function* GET_VIDEO_CLIPS_BY_CLASS({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVideoClipsLoading: true,
    },
  })

  const response = yield call(getVideoClipsByClass, payload)
  if (response) {
    yield put({
      type: ANNOTATION_FOR_STAFF.SET_STATE,
      payload: {
        annotationClips: response.videoClipsByVideoId.annotatorClips,
        verifyingClips: response.videoClipsByVideoId.verifierClips,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVideoClipsLoading: false,
    },
  })
}

export function* CREATE_UPDATE_ANNOTATION_BY_ANNOTATOR({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotationSaving: true,
    },
  })
  const response = yield call(createUpdateAnnotationByAnnotator, payload)
  if (response) {
    notification.success({
      message: 'Annotation Created Successfully!!',
    })
    yield put({
      type: ANNOTATION_FOR_STAFF.APPEND_ANNOTATOR_ANNOTATION,
      payload: {
        data: response.createAnnotationByAnnotator.annotationList,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotationSaving: false,
    },
  })
}
export function* CREATE_UPDATE_ANNOTATION_BY_VERIFIER({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotationSaving: true,
    },
  })
  const response = yield call(createUpdateAnnotationByVerifier, payload)
  if (response) {
    notification.success({
      message: 'Annotation Created Successfully!!',
    })
    yield put({
      type: ANNOTATION_FOR_STAFF.APPEND_VERIFIER_ANNOTATION,
      payload: {
        data: response.createAnnotationByVerifier.annotationList,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotationSaving: false,
    },
  })
}

export function* GET_ANNOTATE_INIT({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotateInitLoading: true,
    },
  })
  const response = yield call(getAnnotateInit, payload)
  if (response) {
    yield put({
      type: ANNOTATION_FOR_STAFF.SET_STATE,
      payload: {
        selectedSubClassAnnotateInit: response,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotateInitLoading: false,
    },
  })
}

export function* SUBMIT_VIDEO_ANNOTATION_BY_ANNOTATOR({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotatorSumission: true,
    },
  })
  const response = yield call(submitVideoAnnotationByAnnotator, payload)
  if (response && response.submitVideoAnnotationsByAnnotator.status) {
    notification.success({
      message: response.submitVideoAnnotationsByAnnotator.message,
    })
    yield put({
      type: ANNOTATION_FOR_STAFF.REPLACE_ANNOTATOR_STATUS,
      payload: {
        data: response.submitVideoAnnotationsByAnnotator.videoDetails,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnnotatorSumission: false,
    },
  })
}

export function* SUBMIT_VIDEO_ANNOTATION_BY_VERIFIER({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVerifierSubmission: true,
      isReAssignSaving: payload.status === 're-assigned',
      isVerifiedSaving: payload.status === 'verified',
    },
  })
  const response = yield call(submitVideoAnnotationByVerifier, payload)
  if (response && response.updateVideoStatusByVerifier.status) {
    notification.success({
      message: response.updateVideoStatusByVerifier.message,
    })
    if (response.updateVideoStatusByVerifier.videoDetails.status === 'verified') {
      yield put({
        type: ANNOTATION_FOR_STAFF.GENERATE_ANVIL_BACKGROUND,
        payload: {
          videoId: payload?.videoId,
        },
      })
    }
    yield put({
      type: ANNOTATION_FOR_STAFF.REPLACE_VERIFIER_STATUS,
      payload: {
        data: response.updateVideoStatusByVerifier.videoDetails,
      },
    })
    yield put({
      type: ANNOTATION_FOR_STAFF.SET_STATE,
      payload: {
        isVerifierSubmissionModal: false,
        isReAssignSaving: false,
        isVerifiedSaving: false,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isVerifierSubmission: false,
    },
  })
}

export function* GET_PREVIEW_ANVIL_DATA({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnvilDataLoading: true,
    },
  })
  const response = yield call(getAnvilData, payload)
  if (response) {
    yield put({
      type: ANNOTATION_FOR_STAFF.SET_STATE,
      payload: {
        anvilData: response.previewAnvilFile.anvilData,
      },
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnvilDataLoading: false,
    },
  })
}

export function* GENERATE_ANVIL_BACKGROUND({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnvilGeneratingBackground: true,
    },
  })
  const response = yield call(generateAnvilBackground, payload)
  if (response && response.generateAnvil.status) {
    notification.success({
      message: response.generateAnvil.message,
    })
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isAnvilGeneratingBackground: false,
    },
  })
}

export function* DELETE_ANNOTATION({ payload }) {
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isDeleting: true,
    },
  })
  const response = yield call(deleteAnnotation, payload)
  if (response && response.deleteAnnotation.status) {
    notification.success({
      message: 'Annotation',
      description: `${response.deleteAnnotation.msg}`,
    })
    const { selectedTab } = yield select(state => state.annotationForStaff)
    if (selectedTab === 'annotator') {
      yield put({
        type: ANNOTATION_FOR_STAFF.POP_ANNOTATOR_ANNOTATION,
        payload: {
          data: { ...payload },
        },
      })
      yield put({
        type: ANNOTATION_CLIP.POP_CLIP,
        payload: {
          data: { ...payload },
        },
      })
    }
    if (selectedTab === 'verifier') {
      yield put({
        type: ANNOTATION_FOR_STAFF.POP_VERIFIER_ANNOTATION,
        payload: {
          data: { ...payload },
        },
      })
    }
  }
  yield put({
    type: ANNOTATION_FOR_STAFF.SET_STATE,
    payload: {
      isDeleting: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(ANNOTATION_FOR_STAFF.GET_ASSIGNED_VIDEOS, GET_ASSIGNED_VIDEOS),
    takeEvery(ANNOTATION_FOR_STAFF.GET_VIDEO_BY_ID, GET_VIDEO_BY_ID),

    takeLatest(ANNOTATION_FOR_STAFF.GET_VIDEO_CLASS, GET_VIDEO_CLASS),
    takeLatest(ANNOTATION_FOR_STAFF.GET_VIDEO_CLIPS_BY_CLASS, GET_VIDEO_CLIPS_BY_CLASS),
    takeLatest(
      ANNOTATION_FOR_STAFF.CREATE_UPDATE_ANNOTATION_BY_ANNOTATOR,
      CREATE_UPDATE_ANNOTATION_BY_ANNOTATOR,
    ),
    takeLatest(
      ANNOTATION_FOR_STAFF.CREATE_UPDATE_ANNOTATION_BY_VERIFIER,
      CREATE_UPDATE_ANNOTATION_BY_VERIFIER,
    ),
    takeEvery(ANNOTATION_FOR_STAFF.GET_ANNOTATE_INIT, GET_ANNOTATE_INIT),
    takeEvery(
      ANNOTATION_FOR_STAFF.SUBMIT_VIDEO_ANNOTATION_BY_ANNOTATOR,
      SUBMIT_VIDEO_ANNOTATION_BY_ANNOTATOR,
    ),
    takeEvery(
      ANNOTATION_FOR_STAFF.SUBMIT_VIDEO_ANNOTATION_BY_VERIFIER,
      SUBMIT_VIDEO_ANNOTATION_BY_VERIFIER,
    ),
    takeEvery(ANNOTATION_FOR_STAFF.GET_PREVIEW_ANVIL_DATA, GET_PREVIEW_ANVIL_DATA),
    takeEvery(ANNOTATION_FOR_STAFF.GENERATE_ANVIL_BACKGROUND, GENERATE_ANVIL_BACKGROUND),
    takeEvery(ANNOTATION_FOR_STAFF.DELETE_ANNOTATION, DELETE_ANNOTATION),
  ])
}
