import { PLAY_DRAWER, STEP_DRAWER } from 'assets/styles/globalStyles'
import actions from './actions'

const initialState = {
  loading: false,
  // get all transcripts
  AllTranscriptList: [],
  IsAllTranscriptListLoading: false,
  AllTranscriptListCount: 0,
  SelectedTabForTranscript: 'annotator',

  // start of transcripts list variables
  TranscriptListLoading: false,
  TranscriptListCount: 0,
  UnAssignedTranscriptList: [],
  TranscriptListPageInfo: null,
  // end of transcripts list variables

  // start of View transcripts data variables
  TranscriptDataLoading: false,
  TranscriptData: [],
  TranscriptDataCount: 0,

  // upload transcript
  IsUploadTranscriptModal: false,
  VideosList: [],
  TotalVideos: 0,
  IsVideosLoading: false,
  IsTranscriptUploading: false,

  // Assign Transcript
  IsAssignTranscriptModal: false,
  SelectedTranscripts: [],
  IsTranscriptAssigning: false,
  IsStaffLoading: false,
  Annotators: [],
  Verifiers: [],
  SelectedTab: 'annotator',

  TranscriptDataSelectedRow: null,
  playDrawerWidth: PLAY_DRAWER.mediumDrawer,
  selectedRow: null,
  EndTimeRow: null,
  StartTimeRow: null,
}

export default function transcriptsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.APPEND_RECORDS:
      return {
        ...state,
        TranscriptData: [...state.TranscriptData, ...action.payload.data],
      }

    case actions.APPEND_VIDEO_TRANSCRIPT:
      return {
        ...state,
        UnAssignedTranscriptList: [...state.UnAssignedTranscriptList, { ...action.payload.data }],
        IsUploadTranscriptModal: false,
      }
    case actions.REMOVE_ASSIGNED_TRANSCRIPT: {
      const removedVideoIds = action.payload.data.map(node => node.id)
      const updatedUnassignedVideos = state.UnAssignedTranscriptList.filter(
        trsnacript => !removedVideoIds.includes(trsnacript.id),
      )
      return {
        ...state,
        SelectedTranscripts: [],
        IsAssignTranscriptModal: false,
        UnAssignedTranscriptList: updatedUnassignedVideos,
      }
    }

    case actions.UPDATE_TRANSCRIPT_DATA:
      return {
        ...state,
        TranscriptData: state.TranscriptData.map(obj =>
          obj.id === action.payload.updatedData.id
            ? {
                ...action.payload.updatedData,
              }
            : obj,
        ),
      }
    case actions.UPDATE_BLOCK_IN_TRANSCRIPT_DATA:
      return {
        ...state,
        TranscriptData: state.TranscriptData.map(obj => {
          const index = action.payload.updatedData.findIndex(item => item.id === obj.id)
          if (index !== -1) {
            return {
              ...obj,
              annotationBlock: action.payload.updatedData[index].annotationBlock, // Assuming this is the correct property to access
            }
            // eslint-disable-next-line no-else-return
          } else {
            return obj
          }
        }),
      }

    default:
      return state
  }
}
