/* eslint-disable import/no-extraneous-dependencies */
import { notification } from 'antd'
// import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { createHttpLink } from 'apollo-link-http'
import { REACT_APP_API_URL } from './REACT_APP_URL'

const API_END_POINT = `${REACT_APP_API_URL}/graphql`

const httpLink = createHttpLink({
  uri: API_END_POINT,
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) =>
      notification.error({
        message,
        duration: 10,
        // description: message,
      }),
    )
  }
})

const httperrorLink = errorLink.concat(httpLink)

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = ''
  if (!(localStorage.getItem('token') === null) && localStorage.getItem('token')) {
    token = JSON.parse(localStorage.getItem('token'))
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      database: 'india',
      Authorization: token ? `JWT ${token}` : '',
    },
  }
})

const middlewareLink = new ApolloLink((operation, forward) => {
  let token = ''
  if (!(localStorage.getItem('token') === null) && localStorage.getItem('token')) {
    token = JSON.parse(localStorage.getItem('token'))
  }
  // Remove the 'Content-Type' header if it's set to 'application/json'
  const headers = { ...operation.getContext().headers }
  if (headers['Content-Type'] === 'application/json') {
    delete headers['Content-Type']
  }
  if (operation.variables && operation.variables.file) {
    operation.setContext({
      headers: {
        // ...headers,
        Authorization: token ? `JWT ${token}` : '',
        // 'Access-Control-Allow-Origin': '*',
        // database: 'india',
        // 'Content-Type': 'multipart/form-data',
      },
    })
  }
  return forward(operation)
})

const authhttperrorLink = authLink.concat(httperrorLink)
const authUploadhttperrorLink = middlewareLink.concat(httperrorLink)

// Create an upload link using createUploadLink
const uploadLink = createUploadLink({
  uri: API_END_POINT,
})

const uploadClient = new ApolloClient({
  link: authUploadhttperrorLink.concat(uploadLink),
  cache: new InMemoryCache(),
})

export default new ApolloClient({
  link: authhttperrorLink,
  cache: new InMemoryCache(),
})

export { uploadClient }
