import actions from './actions'

const initialState = {
  isAddPDFDrawer: false,
  isPDFSaving: false,
  loading: false,
  annotationPDFs: [],
  isSelectedPdfLoading: false,
  selectedPDF: null,
  highlights: [],
  addAnnotationLoading: false,
  editAnnotationLoadinng: false,
  isAnnotationLevel1Loading: false,
  annotationLevel1: [],
  isAddAnnotationModal: false,
  selectedAnnotationPositionData: null,
  isAnnotationSaving: false,
  selectedHighlight: null,
}

export default function annotationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
