/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getLearners(payload) {
  return apolloClient
    .query({
      query: gql`
        query getStudentInfo($after: String, $first: Int, $name: String) {
          getStudentInfo(after: $after, first: $first, name_Icontains: $name) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                name
                enrolmentCode
                age
                gender
                diagnosis {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getTargets(payload) {
  return apolloClient
    .query({
      query: gql`
        query getTargetByStudentId($studentId: ID!, $name: String) {
          getTargetByStudentId(studentId: $studentId, targetName: $name) {
            edges {
              node {
                id
                targetName
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function manageTarget(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createOrUpdateTarget($studentId: ID!, $name: String!, $targetId: ID) {
          createOrUpdateTarget(
            input: { studentId: $studentId, targetName: $name, targetId: $targetId }
          ) {
            message
            status
            studentTarget {
              id
              targetName
              student {
                id
                name
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getTargetTemplate(payload) {
  return apolloClient
    .query({
      query: gql`
        query {
          getTargetEntryTemplate
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
