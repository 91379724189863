import React from 'react'
import AddClinicNotification from './AddClinicNotification'

const PushNotification = ({ form }) => {
  return (
    <div
      style={{
        maxWidth: '600px',
        margin: '20px auto',
        padding: '20px 40px',
        backgroundColor: '#fff',
      }}
    >
      <h1>Add Push Notification</h1>
      <AddClinicNotification form={form} />
    </div>
  )
}
export default PushNotification
