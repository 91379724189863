/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react'
import { useDispatch, connect, useSelector } from 'react-redux'
import { Button, Col, Menu, Row } from 'antd'
import { COLORS } from 'assets/styles/globalStyles'

import { Link, withRouter } from 'react-router-dom'
import { useLazyQuery } from 'react-apollo'
import { TiArrowBack } from 'react-icons/ti'
import gql from 'graphql-tag'
import _ from 'lodash'
import store from 'store'

import styles from './style.module.scss'
import ProfileMenu from './ProfileMenu'

const { SubMenu, Divider } = Menu

export const GET_POSITION_MENU = gql`
  query staffPositionMenu($positionId: ID!) {
    staffPositionMenu(staffPositionId: $positionId) {
      staffPosition {
        id
        title
      }
      clinic {
        schoolName
      }
      filteredKeys
    }
  }
`

const MenuTop = props => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { role, annotationStaff } = user
  const position = user?.staffObject?.position

  const [getStaffPositionMenus] = useLazyQuery(GET_POSITION_MENU, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (position !== null && position !== undefined) {
      getStaffPositionMenus({ variables: { positionId: position?.id } })
    }
  }, [user, position])

  useEffect(() => {
    setSelectedKeysFun(props)
  }, [])

  const setSelectedKeysFun = tt => {
    const { menuData } = props
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), ['url', tt.location.pathname])

    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const handleClick = e => {
    const { isSettingsOpen } = props
    store.set('app.menu.selectedKeys', [e.key])
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }

    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const { menuData = [] } = props

    const generateItem = (item, isSubMenu) => {
      const { key, title, url, icon, pro, disabled } = item

      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item
            key={key}
            title={title}
            style={{ padding: '0px 12px', textAlign: 'left', minWidth: 80 }}
          >
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && (
                  <>
                    <span
                      style={{ margin: 0 }}
                      title={title}
                      className={`${icon} ${styles.icon}`}
                    />
                    <p style={{ marginTop: -13, lineHeight: '10px', fontSize: 10 }}>{title}</p>
                  </>
                )}
                {isSubMenu && (
                  <>
                    <span className={styles.title}>
                      {/* <Trans>{title}</Trans> */}
                      {title}
                    </span>
                  </>
                )}
                {pro && <span className="badge badge-primary ml-2">PRO</span>}
              </a>
            ) : (
              <Link to={url}>
                {icon && (
                  <div style={{ margin: 0, textAlign: 'center' }}>
                    <span
                      style={{ margin: 0 }}
                      title={title}
                      className={`${icon} ${styles.icon}`}
                    />
                    <p style={{ marginTop: -13, lineHeight: '10px', fontSize: 10 }}>{title}</p>
                  </div>
                )}
                {isSubMenu && (
                  <>
                    <span className={styles.title}>{title}</span>
                  </>
                )}
                {pro && <span className="badge badge-primary ml-2">PRO</span>}
              </Link>
            )}
          </Menu.Item>
        )
      }

      return (
        <Menu.Item key={key} title={title} disabled={disabled}>
          {icon && (
            <>
              <span style={{ margin: 0 }} title={title} className={`${icon} ${styles.icon}`} />
              <p style={{ marginTop: -13, lineHeight: '10px', fontSize: 10 }}>{title}</p>
            </>
          )}
          <>
            <span className={styles.title}>{/* <Trans>{title}</Trans> */}</span>
            {title}
          </>
          {pro && <span className="badge badge-primary ml-2">PRO</span>}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span className={styles.menu} key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && (
                <span style={{ margin: 0 }} className={`${menuItem.icon} ${styles.icon}`} />
              )}
            </span>
          )
          return (
            <SubMenu style={{ minWidth: 180 }} title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem, true)
      })

    const menuItems =
      role === 'annotation_staff' && annotationStaff && !annotationStaff.isAnnotator
        ? menuData.filter(item => item.title !== 'Annotation')
        : menuData
    return menuItems.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <div
            className={styles.menu}
            key={menuItem.key}
            style={{ marginBottom: '11px', display: 'block' }}
          >
            {menuItem.icon && (
              <>
                <span
                  style={{ margin: 0 }}
                  title={menuItem.title}
                  className={`${menuItem.icon} ${styles.icon}`}
                />
                <p style={{ fontSize: 10, lineHeight: '13px', marginTop: -14, marginBottom: 3 }}>
                  {menuItem.title}
                </p>
              </>
            )}
          </div>
        )
        return (
          <SubMenu
            title={subMenuTitle}
            key={menuItem.key}
            style={{ padding: '0px 0px', textAlign: 'center', minWidth: 80 }}
          >
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem, false)
    })
  }

  const {
    isLightTheme,
    history: { goBack },
  } = props

  return (
    <div>
      <Row
        style={{
          width: '100%',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          borderBottom: role === 'parents' ? '1px solid #dee2e6' : 'none',
        }}
      >
        <Col md={18} xl={20} xs={20} className="menu">
          <div className={styles.logo}>
            <div
              className={styles.logoContainer}
              style={{ backgroundColor: 'white', borderBottom: '1px solid #f2f2f2' }}
            >
              <img
                src="resources/images/HeaderLogo.png"
                alt="Annotation Logo"
                style={{ marginLeft: '0px', height: 40, marginTop: -8 }}
              />
            </div>
          </div>
          <Menu
            theme={isLightTheme ? 'light' : 'dark'}
            onClick={handleClick}
            selectedKeys={selectedKeys}
            mode="horizontal"
          >
            {generateMenuItems()}

            {/* <Menu.Item
              style={{ padding: '2px 14px', textAlign: 'center', float: 'right', height: '58px' }}
              onClick={goBack}
            ></Menu.Item>
            <Menu.Item
              style={{ padding: '8px 14px', textAlign: 'center', float: 'right', height: '58px' }}
            >
              <ProfileMenu />
            </Menu.Item> */}
          </Menu>
        </Col>
        <Col
          md={2}
          xl={2}
          xs={2}
          className="ant-menu-overflow-item ant-menu-item-only-child"
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            borderBottom: '1px solid #dee2e6',
          }}
        >
          <Button
            type="link"
            onClick={goBack}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: COLORS.blackTitle,
              paddingInlineEnd: 0,
            }}
            className="ant-menu-title-content"
          >
            <TiArrowBack size={20} style={{ marginRight: 5 }} /> Back
          </Button>
        </Col>
        <Col
          md={3}
          xl={2}
          xs={2}
          className="ant-menu-overflow-item ant-menu-item-only-child"
          style={{
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            borderBottom: '1px solid #dee2e6',
          }}
        >
          <div>
            <div className="ant-menu-title-content ant-menu-submenu" style={{ marginLeft: 10 }}>
              <ProfileMenu />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ menu, settings, school, staff, user }) => ({
  menuData: menu.menuTopData,
  isLightTheme: settings.isLightTheme,
  isSettingsOpen: settings.isSettingsOpen,
  school,
  staff,
  user,
})

export default withRouter(connect(mapStateToProps)(MenuTop))
