/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-const */
import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import {
  assignVideoToAnnotator,
  assignVideoToVerifier,
  getAnnotationStaffByID,
  getAnnotationStaffs,
  getAssignVideosAsAnnotator,
  getAssignVideosAsVerifier,
  getUnassignedVideosBySuperadmin,
} from 'services/annotationVideos'

import { notification } from 'antd'
import ANNOTATION_VIDEOS from './actions'

export function* GET_ANNOTATION_STAFFS({ payload }) {
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isStaffsLoading: true,
    },
  })

  const response = yield call(getAnnotationStaffs, { ...payload })
  if (response) {
    yield put({
      type: ANNOTATION_VIDEOS.SET_STATE,
      payload: {
        annotationStaffs: response.getAnnotationStaff.edges,
      },
    })
  }
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isStaffsLoading: false,
    },
  })
}

export function* GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN({ payload }) {
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isUnAssignedVideosLoading: true,
    },
  })

  const response = yield call(getUnassignedVideosBySuperadmin, {
    ...payload,
  })
  if (response && response.getUnassignedVideosBySuperadmin) {
    yield put({
      type: ANNOTATION_VIDEOS.SET_STATE,
      payload: {
        unAssignedVideos: response.getUnassignedVideosBySuperadmin.edges,
        unAssignedVideosCount: response.getUnassignedVideosBySuperadmin.totalCount,
      },
    })
  }
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isUnAssignedVideosLoading: false,
    },
  })
}

export function* GET_ASSIGNED_VIDEOS_AS_ANNOTATOR({ payload }) {
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isAnnotatorVideosLoading: true,
    },
  })

  const response = yield call(getAssignVideosAsAnnotator, payload)
  if (response && response.getAssignedVideosBySuperadmin) {
    yield put({
      type: ANNOTATION_VIDEOS.SET_STATE,
      payload: {
        allAssignedVideosAsAnnotator: response.getAssignedVideosBySuperadmin.edges,
      },
    })
  }
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isAnnotatorVideosLoading: false,
    },
  })
}

export function* GET_ASSIGNED_VIDEOS_AS_VERIFIER({ payload }) {
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isVerifierVideoLoading: true,
    },
  })

  const response = yield call(getAssignVideosAsVerifier, payload)
  if (response && response.getAssignedVideosBySuperadmin) {
    yield put({
      type: ANNOTATION_VIDEOS.SET_STATE,
      payload: {
        allAssignedVideosAsVerifier: response.getAssignedVideosBySuperadmin.edges,
      },
    })
  }
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isVerifierVideoLoading: false,
    },
  })
}

export function* ASSIGN_VIDEO_TO_ANNOTATOR({ payload }) {
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isAnnotatorVideoSaving: true,
    },
  })

  const response = yield call(assignVideoToAnnotator, payload)
  if (response && response.assignVideoToAnnotator.status) {
    notification.success({
      message: response.assignVideoToAnnotator.message,
    })
    yield put({
      type: ANNOTATION_VIDEOS.GET_ANNOTATION_STAFF_BY_ID,
      payload: {
        id: payload.staffId,
      },
    })
    yield put({
      type: ANNOTATION_VIDEOS.REMOVE_ASSIGNED_VIDEOS,
      payload: {
        data: response.assignVideoToAnnotator.assignedVideos,
      },
    })
  }
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isAnnotatorVideoSaving: false,
    },
  })
}

export function* ASSIGN_VIDEO_TO_VERIFIER({ payload }) {
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isVerifyVideoSaving: true,
    },
  })

  const response = yield call(assignVideoToVerifier, payload)
  if (response && response.assignVideoToAnnotatorAndVerifier.status) {
    notification.success({
      message: response.assignVideoToAnnotatorAndVerifier.message,
    })
    yield put({
      type: ANNOTATION_VIDEOS.GET_ANNOTATION_STAFF_BY_ID,
      payload: {
        id: payload.staffId,
      },
    })
    yield put({
      type: ANNOTATION_VIDEOS.REMOVE_ASSIGNED_VIDEOS,
      payload: {
        data: response.assignVideoToAnnotatorAndVerifier.assignedVideos,
      },
    })
  }
  yield put({
    type: ANNOTATION_VIDEOS.SET_STATE,
    payload: {
      isVerifyVideoSaving: false,
    },
  })
}
export function* GET_ANNOTATION_STAFF_BY_ID({ payload }) {
  const response = yield call(getAnnotationStaffByID, payload)
  if (response && response.getAnnotationStaff.edges.length) {
    const staff = response.getAnnotationStaff.edges[0].node
    const { annotationStaffs } = yield select(state => state.annotationVideos)

    yield put({
      type: ANNOTATION_VIDEOS.SET_STATE,
      payload: {
        annotationStaffs: annotationStaffs.map(({ node }) => {
          if (node.id === staff.id) {
            return { node: staff }
          }
          return { node }
        }),
      },
    })
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(ANNOTATION_VIDEOS.GET_ANNOTATION_STAFFS, GET_ANNOTATION_STAFFS),

    takeLatest(
      ANNOTATION_VIDEOS.GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN,
      GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN,
    ),
    takeLatest(
      ANNOTATION_VIDEOS.GET_ASSIGNED_VIDEOS_AS_ANNOTATOR,
      GET_ASSIGNED_VIDEOS_AS_ANNOTATOR,
    ),
    takeLatest(ANNOTATION_VIDEOS.GET_ASSIGNED_VIDEOS_AS_VERIFIER, GET_ASSIGNED_VIDEOS_AS_VERIFIER),
    takeLatest(ANNOTATION_VIDEOS.ASSIGN_VIDEO_TO_ANNOTATOR, ASSIGN_VIDEO_TO_ANNOTATOR),
    takeLatest(ANNOTATION_VIDEOS.ASSIGN_VIDEO_TO_VERIFIER, ASSIGN_VIDEO_TO_VERIFIER),
    takeLatest(ANNOTATION_VIDEOS.GET_ANNOTATION_STAFF_BY_ID, GET_ANNOTATION_STAFF_BY_ID),
  ])
}
