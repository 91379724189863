import React from 'react'
import gql from 'graphql-tag'
import { Button, Form, Input, notification } from 'antd'
import { useMutation } from 'react-apollo'

const ADD_CLINIC_NOTIFICATION = gql`
  mutation sendNotifications($msg: String!) {
    sendNotifications(input: { msg: $msg, toClinics: true, toStudents: false, toStaffs: false }) {
      msg
      status
    }
  }
`

const AddClinicNotification = ({ form }) => {
  const [sendNotification] = useMutation(ADD_CLINIC_NOTIFICATION)

  const onSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        sendNotification({ variables: { msg: values.msg } })
          .then(() => {
            form.resetFields()
            notification.success({
              message: 'Success',
              description: 'Notification sent successfully',
            })
          })
          .catch(error => {
            console.log(error)
            notification.error({
              message: 'Something went wrong',
            })
          })
      }
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Form.Item label="Message">
        {form.getFieldDecorator('msg', {
          rules: [{ required: true, message: 'Please enter message' }],
        })(<Input.TextArea type="text" rows={5} placeholder="Notification message" />)}
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Send Notification
      </Button>
    </Form>
  )
}
export default AddClinicNotification
