const ANNOTATION_STAFFS = {
  SET_STATE: 'annotationStaffs/SET_STATE',
  PAGE_CHANGED: 'annotationStaffs/PAGE_CHANGED',
  GET_ANNOTATION_STAFFS: 'annotationStaffs/GET_ANNOTATION_STAFFS',
  CREATE_ANNOTATION_STAFF: 'annotationStaffs/CREATE_ANNOTATION_STAFF',
  APPEND_ANNOTATION_STAFF: 'annotationStaffs/APPEND_ANNOTATION_STAFF',
  UPDATE_ANNOTATION_STAFF: 'annotationStaffs/UPDATE_ANNOTATION_STAFF',
  REPLACE_ANNOTATION_STAFF: 'annotationStaffs/REPLACE_ANNOTATION_STAFF',

  GET_VIDEOS: 'annotationStaffs/GET_VIDEOS',
  GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN: 'annotationStaffs/GET_UNASSIGNED_VIDEOS_BY_SUPERADMIN',

  GET_ASSIGNED_VIDEOS_AS_ANNOTATOR: 'annotationStaffs/GET_ASSIGNED_VIDEOS_AS_ANNOTATOR',
  ASSIGN_VIDEO_TO_ANNOTATOR: 'annotationStaffs/ASSIGN_VIDEO_TO_ANNOTATOR',
  APPEND_ASSIGNED_ANNOTATE_VIDEO: 'annotationStaffs/APPEND_ASSIGNED_ANNOTATE_VIDEO',

  GET_ASSIGNED_VIDEOS_AS_VERIFIER: 'annotationStaffs/GET_ASSIGNED_VIDEOS_AS_VERIFIER',
  ASSIGN_VIDEO_TO_VERIFIER: 'annotationStaffs/ASSIGN_VIDEO_TO_VERIFIER',
  APPEND_ASSIGNED_VERIFY_VIDEO: 'annotationStaffs/APPEND_ASSIGNED_VERIFY_VIDEO',
}

export default ANNOTATION_STAFFS
