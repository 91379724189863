/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getAnnotationLearners(payload) {
  return apolloClient
    .query({
      query: gql`
        query getStudentInfo($offset: Int, $first: Int, $name: String, $age: Float) {
          getStudentInfo(name_Icontains: $name, first: $first, age: $age, offset: $offset) {
            totalCount
            edges {
              node {
                id
                name
                enrolmentCode
                age
                gender
                diagnosis {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function createUpdateAnnotationLearner(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createUpdateStudentInfo(
          $id: ID
          $enrolmentCode: String
          $name: String
          $ageInMonths: Int
          $gender: String
          $diagnosis: ID
        ) {
          createUpdateStudentInfo(
            input: {
              pk: $id
              enrolmentCode: $enrolmentCode
              name: $name
              ageInMonths: $ageInMonths
              gender: $gender
              diagnosis: $diagnosis
            }
          ) {
            status
            message
            studentInfo {
              id
              name
              enrolmentCode
              age
              gender
              diagnosis {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getDiagnosis() {
  return apolloClient
    .query({
      query: gql`
        query {
          getDiagnosis {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createDiagnosis(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createDiagnosis($name: String!) {
          createDiagnosis(input: { name: $name }) {
            status
            message
            diagnosisInfo {
              id
              name
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
