import ANNOTATION_LEARNERS from './actions'

const initialState = {
  annotationLearners: [],
  isLearnersLoading: false,
  isLearnerSaving: false,
  selectedLearner: null,
  isCreateLearnerDrawer: false,
  isDiagnosisLoading: false,
  diagnosis: [],
  isDiagnosisSaving: false,
  isDiagnosisModal: false,

  // pagination
  learnerCount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ANNOTATION_LEARNERS.SET_STATE:
      return { ...state, ...action.payload }
    case ANNOTATION_LEARNERS.REPLACE_ANNOTATION_LEARNER: {
      const updatedAnnotationLearners = state.annotationLearners.map(({ node }) => {
        if (node.id === action.payload.data.id) {
          return { node: { ...action.payload.data } }
        }
        return { node }
      })

      // Check if the payload ID was found in the annotationLearners
      const isMatchFound = updatedAnnotationLearners.some(
        ({ node }) => node.id === action.payload.data.id,
      )

      return {
        ...state,
        annotationLearners: isMatchFound
          ? updatedAnnotationLearners
          : [...updatedAnnotationLearners, { node: { ...action.payload.data } }],
        isCreateLearnerDrawer: false,
        selectedLearner: null,
        isLearnerSaving: false,
      }
    }

    case ANNOTATION_LEARNERS.APPEND_DIAGNOSIS:
      return {
        ...state,
        isDiagnosisSaving: false,
        isDiagnosisModal: false,
        diagnosis: [...state.diagnosis, { node: { ...action.payload.data } }],
      }

    default:
      return state
  }
}
