/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getAssignedVideos(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAssignedVideos(
          $staffType: String!
          $name: String
          $first: Int
          $status: String
          $annotator: ID
          $offset: Int
        ) {
          getAssignedVideosByStaff(
            staffType: $staffType
            offset: $offset
            first: $first
            name_Icontains: $name
            status: $status
            annotator: $annotator
          ) {
            totalCount
            edges {
              node {
                id
                name
                status
                duration
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function getVideoById(payload) {
  return apolloClient
    .query({
      query: gql`
        query VideoObject($id: ID!) {
          videoUrl(id: $id) {
            id
            name
            createdAt
            url
            signedUrl
            status
            duration
            student {
              id
            }
            transcriptfilesSet {
              edges {
                node {
                  id
                }
              }
            }
            annotator {
              id
              firstname
              lastname
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function getVideoClass() {
  return apolloClient
    .query({
      query: gql`
        query {
          videoClass {
            id
            name
            videosubclassSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideoClipsByClass(payload) {
  return apolloClient
    .query({
      query: gql`
        query videoClipsByVideoId($videoId: ID!, $videoClass: ID!) {
          videoClipsByVideoId(videoId: $videoId, videoClass: $videoClass) {
            annotatorClips {
              id
              startTime
              endTime
              note
              annotationStatus
              subclass {
                id
                name
                videoclass {
                  id
                  name
                }
              }
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              behaviours {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              emotions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jointAttentions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
              transStimulus
              target {
                id
                targetName
              }
              transResponse
              errorCorrectionProcdure
              promptUsed {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              clinicalSuggestion
              socialOrientation {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              socialOrientation2 {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function createAnnotationStaff(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createAnnotationStaff(
          $firstName: String!
          $lastName: String
          $email: String!
          $isAnnotator: Boolean!
          $isVerifier: Boolean!
          $password: String!
        ) {
          createAnnotationStaff(
            input: {
              firstname: $firstName
              lastname: $lastName
              email: $email
              isAnnotator: $isAnnotator
              isVerifier: $isVerifier
              password: $password
            }
          ) {
            message
            status
            annotationStaff {
              id
              email
              firstname
              lastname
              user {
                id
                username
                firstName
                lastName
              }
              isAnnotator
              isVerifier
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotateInit(payload) {
  return apolloClient
    .query({
      query: gql`
        query AnnotateInit($subId: ID!) {
          vfeature(subId: $subId) {
            id
            name
          }
          vstimulus(subId: $subId) {
            id
            name
          }
          vcategory(subId: $subId) {
            id
            name
          }
          vresponse(subId: $subId) {
            id
            name
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createUpdateAnnotationByAnnotator(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createAnnotationByAnnotator(
          $videoId: ID!
          $subId: ID
          $startTime: Float
          $endTime: Float
          $selfeature: ID
          $selstimulus: ID
          $selcategory: ID
          $selresponse: ID
          $selcomment: String
          $clipId: ID
        ) {
          createAnnotationByAnnotator(
            input: {
              videoId: $videoId
              subId: $subId
              startTime: $startTime
              endTime: $endTime
              feature: $selfeature
              stimulus: $selstimulus
              category: $selcategory
              response: $selresponse
              note: $selcomment
              clipId: $clipId
            }
          ) {
            annotationList {
              id
              startTime
              endTime
              note
              annotationStatus
              subclass {
                id
                name
                videoclass {
                  id
                  name
                }
              }
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              behaviours {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              emotions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jointAttentions {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
              transStimulus
              target {
                id
                targetName
              }
              transResponse
              errorCorrectionProcdure
              promptUsed {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              clinicalSuggestion
              socialOrientation {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              socialOrientation2 {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function createUpdateAnnotationByVerifier(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createAnnotationByVerifier(
          $videoId: ID!
          $subId: ID
          $startTime: Float
          $endTime: Float
          $selfeature: ID
          $selstimulus: ID
          $selcategory: ID
          $selresponse: ID
          $selcomment: String
          $clipId: ID
        ) {
          createAnnotationByVerifier(
            input: {
              videoId: $videoId
              subId: $subId
              startTime: $startTime
              endTime: $endTime
              feature: $selfeature
              stimulus: $selstimulus
              category: $selcategory
              response: $selresponse
              note: $selcomment
              clipId: $clipId
            }
          ) {
            annotationList {
              id
              startTime
              endTime
              note
              subclass {
                id
                name
              }
              feature {
                id
                name
              }
              stimuli {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              category {
                id
                name
              }
              response {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function deleteAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteAnnotation($pk: ID) {
          deleteAnnotation(input: { pk: $pk }) {
            status
            msg
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function submitVideoAnnotationByAnnotator(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation submitVideoAnnotationsByAnnotator($videoId: ID!) {
          submitVideoAnnotationsByAnnotator(input: { videoId: $videoId }) {
            message
            status
            videoDetails {
              id
              name
              signedUrl
              status
              annotator {
                id
              }
              verifier {
                id
              }
              student {
                id
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function submitVideoAnnotationByVerifier(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateVideoStatusByVerifier($videoId: ID!, $status: String!) {
          updateVideoStatusByVerifier(input: { videoId: $videoId, status: $status }) {
            message
            status
            videoDetails {
              id
              name
              signedUrl
              status
              student {
                id
              }
              annotator {
                id
              }
              verifier {
                id
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnvilData(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation previewAnvilFile($videoId: ID!, $staffType: String!) {
          previewAnvilFile(input: { pk: $videoId, staffType: $staffType }) {
            message
            status
            anvilData
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function generateAnvilBackground(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation generateAnvil($videoId: ID!) {
          generateAnvil(input: { pk: $videoId }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
