/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getAnnotationStaffs(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAnnotationStaff($offset: Int, $first: Int, $name: String, $email: String) {
          getAnnotationStaff(
            firstname_Icontains: $name
            email_Icontains: $email
            offset: $offset
            first: $first
          ) {
            totalCount
            edges {
              node {
                id
                email
                firstname
                lastname
                user {
                  id
                  username
                  firstName
                  lastName
                }
                isAnnotator
                isVerifier
                totalAnnotatorVideos
                pendingAnnotateVideos
                annotatedVideos
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function createAnnotationStaff(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createAnnotationStaff(
          $firstName: String!
          $lastName: String
          $email: String!
          $isAnnotator: Boolean!
          $isVerifier: Boolean!
          $password: String!
        ) {
          createAnnotationStaff(
            input: {
              firstname: $firstName
              lastname: $lastName
              email: $email
              isAnnotator: $isAnnotator
              isVerifier: $isVerifier
              password: $password
            }
          ) {
            message
            status
            annotationStaff {
              id
              email
              firstname
              lastname
              user {
                id
                username
                firstName
                lastName
              }
              isAnnotator
              isVerifier
              totalAnnotatorVideos
              pendingAnnotateVideos
              annotatedVideos
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function updateAnnotationStaff(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateAnnotationStaff(
          $id: ID!
          $firstName: String!
          $lastName: String
          $email: String!
          $isAnnotator: Boolean!
          $isVerifier: Boolean!
        ) {
          updateAnnotationStaff(
            input: {
              id: $id
              firstname: $firstName
              lastname: $lastName
              email: $email
              isAnnotator: $isAnnotator
              isVerifier: $isVerifier
            }
          ) {
            message
            status
            annotationStaff {
              id
              email
              firstname
              lastname
              user {
                id
                username
                firstName
                lastName
              }
              isAnnotator
              isVerifier
              totalAnnotatorVideos
              pendingAnnotateVideos
              annotatedVideos
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getVideos(payload) {
  return apolloClient
    .query({
      query: gql`
        query getVideos($name: String) {
          getVideos(first: 30, name_Icontains: $name) {
            totalCount
            edges {
              node {
                id
                name
                url
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getUnassignedVideosBySuperadmin(payload) {
  return apolloClient
    .query({
      query: gql`
        # staffType: [annotator, verifier]
        query getUnassignedVideosBySuperadmin(
          $staffType: String!
          $before: String
          $after: String
          $first: Int
          $last: Int
          $name: String
        ) {
          getUnassignedVideosBySuperadmin(
            staffType: $staffType
            before: $before
            after: $after
            first: $first
            last: $last
            name_Icontains: $name
          ) {
            totalCount
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAssignVideosAsAnnotator(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAssignedVideosBySuperadmin($staffId: ID!, $name: String) {
          getAssignedVideosBySuperadmin(
            staffType: "annotator"
            annotationStaff: $staffId
            name_Icontains: $name
          ) {
            totalCount
            edges {
              node {
                id
                name
                status
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function assignVideoToAnnotator(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignVideoToAnnotatorAndVerifier($staffId: ID!, $videos: [ID]) {
          assignVideoToAnnotatorAndVerifier(
            input: { annotationStaff: $staffId, annotatorVideos: $videos }
          ) {
            message
            status
            assignedVideos {
              id
              name
              annotator {
                id
                firstname
                lastname
                email
              }
              verifier {
                id
                firstname
                lastname
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function assignVideoToVerifier(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignVideoToAnnotatorAndVerifier($staffId: ID!, $videos: [ID]) {
          assignVideoToAnnotatorAndVerifier(
            input: { annotationStaff: $staffId, verifierVideos: $videos }
          ) {
            message
            status
            assignedVideos {
              id
              name
              annotator {
                id
                firstname
                lastname
                email
              }
              verifier {
                id
                firstname
                lastname
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
export async function getAssignVideosAsVerifier(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAssignedVideosBySuperadmin($staffId: ID!) {
          getAssignedVideosBySuperadmin(staffType: "verifier", annotationStaff: $staffId) {
            totalCount
            edges {
              node {
                id
                name
                status
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
