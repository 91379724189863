import ANNOTATION_FOR_STAFF from './actions'

const initialState = {
  selectedTab: null,
  isAssignedVideosLoading: false,
  assignedVideos: [],
  videosCount: 0,
  videosPageInfo: null,
  videosType: 'next',
  videosPrevPageNo: 1,
  selectedAnnotationVideo: null,
  selectedVerifyVideo: null,

  isVideoDetailsLoading: false,
  videoDetails: null,

  isVideoClassLoading: false,
  videoClass: [],
  selectedClass: null,

  isVideoClipsLoading: false,
  annotationClips: [],
  verifyingClips: [],
  selectedClipIndex: 0,
  selectedClip: null,
  annotationsList: [],

  isAnnotationSaving: false,
  isCreateAnnotationDrawer: false,
  selectedAnnotation: null,

  isAnnotateInitLoading: false,
  selectedSubClassAnnotateInit: null,
  selectedSubClass: null,
  startTime: null,
  endTime: null,

  isAnnotatorSumission: false,
  isVerifierSubmission: false,
  isVerifierSubmissionModal: false,

  isPreviewDrawer: false,
  isAnvilDataLoading: false,
  anvilData: null,
  wantDownload: false,
  isReAssignSaving: false,
  isVerifiedSaving: false,

  isAnvilGeneratingBackground: false,

  // add clip
  isAddClipDrawer: false,
  pausedTime: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ANNOTATION_FOR_STAFF.SET_STATE:
      return { ...state, ...action.payload }

    case ANNOTATION_FOR_STAFF.APPEND_ANNOTATOR_ANNOTATION: {
      const { data } = action.payload

      const mergedData = state.annotationClips.map(itemB => {
        const matchingAnnotation = data.find(itemA => itemA.id === itemB.id)

        if (matchingAnnotation) {
          return {
            ...itemB.node,
            ...matchingAnnotation,
          }
        }

        return itemB
      })

      const remainingItems = data.filter(itemA => !mergedData.some(itemB => itemA.id === itemB.id))

      mergedData.push(...remainingItems)

      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        annotationClips: mergedData,
      }
    }

    case ANNOTATION_FOR_STAFF.APPEND_VERIFIER_ANNOTATION: {
      const { data } = action.payload

      const mergedData = state.verifyingClips.map(itemB => {
        const matchingAnnotation = data.find(itemA => itemA.id === itemB.id)

        if (matchingAnnotation) {
          return {
            ...itemB.node,
            ...matchingAnnotation,
          }
        }

        return itemB
      })

      const remainingItems = data.filter(itemA => !mergedData.some(itemB => itemA.id === itemB.id))

      mergedData.push(...remainingItems)

      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        verifyingClips: mergedData,
      }
    }

    case ANNOTATION_FOR_STAFF.POP_ANNOTATOR_ANNOTATION: {
      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        selectedClip: null,
        annotationClips: state.annotationClips.filter(item => item.id !== action.payload.data.pk),
      }
    }
    case ANNOTATION_FOR_STAFF.POP_VERIFIER_ANNOTATION: {
      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        verifyingClips: state.verifyingClips.filter(item => item.id !== action.payload.data.pk),
      }
    }
    case ANNOTATION_FOR_STAFF.REPLACE_ANNOTATOR_STATUS: {
      return {
        ...state,
        assignedVideos: state.assignedVideos.map(item => {
          if (item.id === action.payload.data.id) {
            return { ...item, ...action.payload.data }
          }
          return item
        }),
        selectedAnnotationVideo: {
          ...state.selectedAnnotationVideo,
          ...action.payload.data,
        },
        videoDetails: {
          ...state.videoDetails,
          ...action.payload.data,
        },
      }
    }
    case ANNOTATION_FOR_STAFF.REPLACE_VERIFIER_STATUS: {
      return {
        ...state,
        assignedVideos: state.assignedVideos.map(item => {
          if (item.id === action.payload.data.id) {
            return { ...item, ...action.payload.data }
          }
          return item
        }),
        selectedVerifyVideo: {
          ...state.selectedVerifyVideo,
          ...action.payload.data,
        },
        videoDetails: {
          ...state.videoDetails,
          ...action.payload.data,
        },
      }
    }

    default:
      return state
  }
}
