import actions from './actions'

const initialState = {
  isAnotatedVideosLoading: false,
  annotatedVideos: [],
  selectedAnnotatedVideo: null,
  videoClips: [],
  // pagination
  videoCount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',

  clipCount: 0,
  clipPageInfo: null,
  clipPrevPageNo: 1,
  clipType: 'next',

  isVideoClipLoading: false,
  isAnvilGenerating: false,
  isVideoDeleting: false,

  isSubClassSaving: false,
  isSubClassDrawer: false,

  isVideoDetailsLoading: false,
  videoDetails: null,

  isAddVideoDrawer: false,
  isVideoSaving: false,
  isStudentLoading: false,
  students: [],
  isAWSVideoListLoading: false,
  AWSVideoList: [],
  videoList: [],
  AWSCount: 0,
  selectedAWSVideo: null,
  AWSVideoPageInfo: null,
  AWSVideoType: 'next',
  AWSVideoPrevPageNo: 1,

  isVideoClassLoading: false,
  videoClass: [],

  selectedAnnotation: null,
  isAnnotationSaving: false,
  isCreateAnnotationDrawer: false,
  isAnnotateInitLoading: false,
  selectedSubClassAnnotateInit: null,
  selectedSubClass: null,
  startTime: null,
  endTime: null,
  isDeleting: false,
  selectedClass: null,
  annotationListOfClass: [],

  isVideoClipsLoading: false,
  annotationClips: [],
  verifyingClips: [],

  sortedAnnotationsList: [],
  sortedVerifyingList: [],

  annotationsList: [],
  selectedClip: null,
  selectedClipIndex: 0,

  isPreviewDrawer: false,
  isAnvilDataLoading: false,
  anvilData: null,
  wantDownload: false,

  isAnvilGeneratingBackground: false,

  isVideoUpdateDrawer: false,
  isVideoUserSaving: false,
  annotationStaffs: [],
  isAnnotationStaffLoading: false,

  isInterrateReportDrawer: false,
  isReportLoading: false,
  reportData: null,
  isVideoStatusModal: false,
  videoStatusSaving: false,

  isAddClipDrawer: false,
  pausedTime: null,

  isAddSubClassDataDrawer: false,
  subClassData: [],
  isSubClassDataLoading: false,
  selectedSubClassData: null,
  behaviours: [],
  emotions: [],
  jointAttentions: [],
  socialOrientation: [],
  prompts: [],
  studentTargets: [],
  isLoadingTargets: false,
  isMoreDataLoading: false,
  isSavingResponse: false,
}

export default function annotationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.APPEND_TARGET: {
      return {
        ...state,
        studentTargets: [
          {
            node: action.payload.target,
          },
          ...state.studentTargets,
        ],
      }
    }
    case actions.POP_VIDEO_ANNOTATION: {
      return {
        ...state,
        annotatedVideos: [...state.annotatedVideos.filter(item => item.id !== action.payload.id)],
      }
    }
    case actions.APPEND_AWS_VIDEO: {
      return {
        ...state,
        isAddVideoDrawer: false,
        AWSVideoList: [{ ...action.payload.data }, ...state.AWSVideoList],
        selectedAWSVideo: action.payload.data,
      }
    }
    case actions.APPEND_ANNOTATION: {
      const { data } = action.payload

      const mergedData = state.videoDetails.videoClips.edges.map(itemB => {
        const matchingAnnotation = data.find(itemA => itemA.id === itemB.node.id)

        if (matchingAnnotation) {
          return {
            node: {
              ...itemB.node,
              ...matchingAnnotation,
            },
          }
        }

        return itemB
      })

      const remainingItems = data.filter(
        itemA => !mergedData.some(itemB => itemA.id === itemB.node.id),
      )

      mergedData.push(...remainingItems.map(item => ({ node: item })))

      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        videoDetails: {
          ...state.videoDetails,
          videoClips: {
            edges: mergedData,
          },
        },
      }
    }
    case actions.APPEND_ANNOTATION_CLIP: {
      const { data } = action.payload
      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        annotationClips:
          state.selectedClass && state.selectedClass.id === data.subclass.videoclass.id
            ? [...state.annotationClips, { ...data }]
            : state.annotationClips,
      }
    }

    case actions.REPLACE_ANNOTATION_CLIP: {
      const { data } = action.payload
      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        annotationClips: state.annotationClips.map(item => {
          if (item.id === data.id) {
            return data
          }
          return item
        }),
      }
    }
    case actions.POP_ANNOTATION: {
      return {
        ...state,
        isCreateAnnotationDrawer: false,
        selectedAnnotation: null,
        videoDetails: {
          ...state.videoDetails,
          videoClips: {
            edges: state.videoDetails.videoClips.edges.filter(
              ({ node }) => node.id !== action.payload.data.pk,
            ),
          },
        },
      }
    }

    case actions.REPLACE_VIDEO: {
      return {
        ...state,
        selectedAnnotatedVideo: null,
        isVideoUpdateDrawer: false,
        annotatedVideos: [
          ...state.annotatedVideos.map(item => {
            if (item.id === action.payload.data.id) {
              return { ...item, ...action.payload.data }
            }
            return item
          }),
        ],
      }
    }
    case actions.REPLACE_VIDEO_STATUS: {
      return {
        ...state,
        selectedAnnotatedVideo: null,
        isVideoStatusModal: false,
        annotatedVideos: [
          ...state.annotatedVideos.map(item => {
            if (item.id === action.payload.data.id) {
              return { ...item, ...action.payload.data }
            }
            return item
          }),
        ],
      }
    }
    default:
      return state
  }
}
