/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, put, takeEvery, call, select, takeLatest } from 'redux-saga/effects'
import {
  getTranscriptList,
  getTranscriptData,
  getVideosForTranscript,
  uploadVideoTranscript,
  getAnnotationStaff,
  assignTranscriptToStaff,
  getUnAssignedTranscriptList,
  getTranscriptBYAnnotationStaff,
  updateTranscript,
  addBlock,
  updateBlock,
} from 'services/transcripts'
import actions from './actions'

export function* GET_ALL_TRANSCRIPT_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsAllTranscriptListLoading: true,
    },
  })

  const response = yield call(getTranscriptList, {
    ...payload,
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        AllTranscriptList: response.getTranscriptsBySuperadmin.edges.map(({ node }) => ({
          ...node,
        })),
        AllTranscriptListCount: response.getTranscriptsBySuperadmin.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsAllTranscriptListLoading: false,
    },
  })
}

export function* GET_ALL_TRANSCRIPT_LIST_BY_STAFF({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsAllTranscriptListLoading: true,
    },
  })

  const response = yield call(getTranscriptBYAnnotationStaff, {
    ...payload,
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        AllTranscriptList: response.getAssignedTranscripts.edges.map(({ node }) => ({
          ...node,
        })),
        AllTranscriptListCount: response.getAssignedTranscripts.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsAllTranscriptListLoading: false,
    },
  })
}

export function* GET_UNASSIGNED_TRANSCRIPT_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      TranscriptListLoading: true,
    },
  })

  const response = yield call(getUnAssignedTranscriptList, {
    ...payload,
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        UnAssignedTranscriptList: response.getUnassignedTranscriptsBySuperadmin.edges.map(
          ({ node }) => ({
            ...node,
          }),
        ),
        TranscriptListCount: response.getUnassignedTranscriptsBySuperadmin.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      TranscriptListLoading: false,
    },
  })
}

export function* GET_TRANSCRIPTS_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      TranscriptDataLoading: true,
      TranscriptData: [],
      TranscriptDataCount: 0,
      selectedRow: null,
      EndTimeRow: null,
      StartTimeRow: null,
      TranscriptDataSelectedRow: null,
    },
  })

  let { first = 10, offset = 0, transcriptId } = payload

  const response = yield call(getTranscriptData, {
    ...payload,
    offset,
    first,
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        TranscriptData: response.getTranscriptDataByFileId.edges.map(({ node }) => ({ ...node })),
        TranscriptDataCount: response.getTranscriptDataByFileId.totalCount,
      },
    })

    // Calculate how many batches of 100 records we need to fetch based on totalRecords
    // eslint-disable-next-line prefer-destructuring
    const totalCount = response.getTranscriptDataByFileId.totalCount
    const remainingBatches = Math.ceil((totalCount - 100) / 100)

    // Fetch remaining records in batches of 100
    const remainingRecords = yield all(
      Array.from({ length: remainingBatches }, (_, index) =>
        call(getTranscriptData, { ...payload, offset: (index + 1) * 100, first: 100 }),
      ),
    )

    // Extract the data from each response and concatenate
    const additionalRecords = remainingRecords
      .map(responseObj =>
        responseObj.getTranscriptDataByFileId.edges.map(({ node }) => ({ ...node })),
      )
      .flat()

    // Dispatch action to append additional records
    yield put({
      type: actions.APPEND_RECORDS,
      payload: {
        data: additionalRecords,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      TranscriptDataLoading: false,
    },
  })
}

export function* GET_VIDEOS_FOR_TRANSCRIPT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsVideosLoading: true,
    },
  })
  let { first = 10, offset = 0 } = payload

  const response = yield call(getVideosForTranscript, {
    ...payload,
    offset,
    first,
  })
  if (response) {
    const { VideosList } = yield select(state => state.transcripts)
    const newVideos = response.getVideos.edges.map(({ node }) => ({ ...node }))
    yield put({
      type: actions.SET_STATE,
      payload: {
        VideosList:
          payload.name && payload.name.length ? [...newVideos] : [...VideosList, ...newVideos],
        TotalVideos: response.getVideos.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsVideosLoading: false,
    },
  })
}

export function* UPLOAD_VIDEO_TRANSCRIPT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsTranscriptUploading: true,
    },
  })
  const response = yield call(uploadVideoTranscript, payload)
  if (response && response.uploadTranscirptFile.status) {
    const { message, transcriptFile } = response.uploadTranscirptFile
    notification.success({
      message,
      duration: 10,
    })
    yield put({
      type: actions.APPEND_VIDEO_TRANSCRIPT,
      payload: {
        data: transcriptFile,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsTranscriptUploading: false,
    },
  })
}

export function* GET_STAFFS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsStaffLoading: true,
    },
  })
  const response = yield call(getAnnotationStaff)
  if (response) {
    const annotators = []
    const verifiers = []
    const { edges } = response.getAnnotationStaff
    if (edges.length) {
      for (let i = 0; i < edges.length; i++) {
        if (edges[i].node.isAnnotator) annotators.push(edges[i].node)
        if (edges[i].node.isVerifier) verifiers.push(edges[i].node)
      }
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        Annotators: annotators,
        Verifiers: verifiers,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsStaffLoading: false,
    },
  })
}

export function* ASSIGN_TRANSCRIPTS_TO_STAFF({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsTranscriptAssigning: true,
    },
  })
  const response = yield call(assignTranscriptToStaff, payload)
  if (response && response.assignTranscriptToAnnotator.status) {
    const { message, transcriptFiles } = response.assignTranscriptToAnnotator
    notification.success({
      message,
    })
    yield put({
      type: actions.REMOVE_ASSIGNED_TRANSCRIPT,
      payload: {
        data: transcriptFiles,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      IsTranscriptAssigning: false,
    },
  })
}

export function* UPDATE_SPEAKER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateTranscript, payload)

  if (response && response.updateTranscriptSpeaker.status) {
    const { transcriptData } = response.updateTranscriptSpeaker

    yield put({
      type: actions.UPDATE_TRANSCRIPT_DATA,
      payload: {
        updatedData: transcriptData,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_CLASS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateTranscript, payload)

  if (response && response.updateTranscriptSpeaker.status) {
    const { transcriptData } = response.updateTranscriptSpeaker

    yield put({
      type: actions.UPDATE_TRANSCRIPT_DATA,
      payload: {
        updatedData: transcriptData,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* ADD_BLOCK_RESPONSE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  if (!payload.object.annotationBlock) {
    const response = yield call(addBlock, payload)
    if (response && response.addBlockOnTranscriptData.status) {
      const { transcriptData } = response.addBlockOnTranscriptData
      yield put({
        type: actions.UPDATE_BLOCK_IN_TRANSCRIPT_DATA,
        payload: {
          updatedData: transcriptData,
        },
      })
    }
  } else {
    const response = yield call(updateBlock, payload)
    if (response && response.updateBlockOnTranscriptData.status) {
      const { transcriptData } = response.updateBlockOnTranscriptData
      yield put({
        type: actions.UPDATE_BLOCK_IN_TRANSCRIPT_DATA,
        payload: {
          updatedData: transcriptData,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* ADD_BLOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(addBlock, payload)
  if (response && response.addBlockOnTranscriptData.status) {
    notification.success({
      message: response.addBlockOnTranscriptData.message,
    })
    const { transcriptData } = response.addBlockOnTranscriptData
    yield put({
      type: actions.UPDATE_BLOCK_IN_TRANSCRIPT_DATA,
      payload: {
        updatedData: transcriptData,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_ALL_TRANSCRIPT_LIST, GET_ALL_TRANSCRIPT_LIST),
    takeLatest(actions.GET_ALL_TRANSCRIPT_LIST_BY_STAFF, GET_ALL_TRANSCRIPT_LIST_BY_STAFF),

    takeLatest(actions.GET_UNASSIGNED_TRANSCRIPT_LIST, GET_UNASSIGNED_TRANSCRIPT_LIST),
    takeLatest(actions.GET_TRANSCRIPTS_DATA, GET_TRANSCRIPTS_DATA),
    takeLatest(actions.GET_VIDEOS_FOR_TRANSCRIPT, GET_VIDEOS_FOR_TRANSCRIPT),
    takeLatest(actions.UPLOAD_VIDEO_TRANSCRIPT, UPLOAD_VIDEO_TRANSCRIPT),
    takeLatest(actions.GET_STAFFS, GET_STAFFS),
    takeLatest(actions.ASSIGN_TRANSCRIPTS_TO_STAFF, ASSIGN_TRANSCRIPTS_TO_STAFF),

    takeLatest(actions.UPDATE_SPEAKER, UPDATE_SPEAKER),
    takeLatest(actions.UPDATE_CLASS, UPDATE_CLASS),
    takeLatest(actions.ADD_BLOCK_RESPONSE, ADD_BLOCK_RESPONSE),
    takeLatest(actions.ADD_BLOCK, ADD_BLOCK),
  ])
}
