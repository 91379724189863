/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, put, takeEvery, call, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import {
  getVideosStatusCount,
  loadStaffData,
  loadClassesData,
  getVideoClassAndSubClass,
  loadAnnotationsCountsData,
  addDataInSubclass,
  createClass,
} from 'services/reports'
import { getDatesBetween } from 'utilities'
import actions from './actions'
import annotationAction from '../Annotation/actions'

const format = 'YYYY-MM-DD'

export function* GET_VIDEO_STATU_COUNTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(getVideosStatusCount)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        VideoCounts: JSON.parse(response.getVideosDashboardBySuperadmin),
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_STAFF_REPORT_DATA() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { SelectedDates, ReportFor, ReportOn, CalculatedMonths, CalculatedWeeks } = yield select(
    state => state.reports,
  )
  // prepare filters
  const payload = {
    staffType: ReportFor,
    dataType: ReportOn,
    dayStartDate: moment(SelectedDates[0]).format(format),
    dayEndDate: moment(SelectedDates[1]).format(format),
    weeksOrMonths: ReportOn === 'weeks' ? CalculatedWeeks : CalculatedMonths,
  }

  const apiResponse = yield call(loadStaffData, payload)
  // eslint-disable-next-line no-undef
  const response = structuredClone(apiResponse)
  if (response) {
    const { data } = response.getStaffReport

    if (data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].data.length) {
          if (ReportOn === 'days') {
            const dates = getDatesBetween(SelectedDates[0], SelectedDates[1])
            if (dates.length) {
              dates.map((date, index) => {
                data[i][`${moment(date).format('MMM D')}`] = {
                  assignedVideosCount: data[i].data[index].assignedVideosCount,
                  totalAnnotatedClips: data[i].data[index].totalAnnotatedClips,

                  assignedVideosClipsCount: data[i].data[index].assignedVideosClipsCount,
                  totalVerifiedClips: data[i].data[index].totalVerifiedClips,
                  markDeleteVideosClips: data[i].data[index].markDeleteVideosClips,
                  markRmltVideosClips: data[i].data[index].markRmltVideosClips,
                }
              })
            }
          }
          if (ReportOn === 'weeks') {
            CalculatedWeeks.map(({ startDate }, index) => {
              data[i][`Week_${moment(startDate).isoWeek()}`] = {
                assignedVideosCount: data[i].data[index].assignedVideosCount,
                totalAnnotatedClips: data[i].data[index].totalAnnotatedClips,

                assignedVideosClipsCount: data[i].data[index].assignedVideosClipsCount,
                totalVerifiedClips: data[i].data[index].totalVerifiedClips,
                markDeleteVideosClips: data[i].data[index].markDeleteVideosClips,
                markRmltVideosClips: data[i].data[index].markRmltVideosClips,
              }
            })
          }
          if (ReportOn === 'months') {
            CalculatedMonths.map(({ startDate }, index) => {
              data[i][`Month_${moment(startDate).format('MMMM')}`] = {
                assignedVideosCount: data[i].data[index].assignedVideosCount,
                totalAnnotatedClips: data[i].data[index].totalAnnotatedClips,

                assignedVideosClipsCount: data[i].data[index].assignedVideosClipsCount,
                totalVerifiedClips: data[i].data[index].totalVerifiedClips,
                markDeleteVideosClips: data[i].data[index].markDeleteVideosClips,
                markRmltVideosClips: data[i].data[index].markRmltVideosClips,
              }
            })
          }
        }
      }
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        StaffData: data,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CLASSES_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(loadClassesData, payload)
  if (response && response.getVideoClassReport) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        ClassesData: response.getVideoClassReport.data,
        TotalCount: response.getVideoClassReport.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

const transformData = (node, index) => {
  const result = {
    name: node.name,
    value: index,
    children: [],
  }

  if (node.feature?.edges) {
    result.children.push({
      name: 'Feature',
      value: node.feature.edges.length,
      children: node.feature.edges.map((feature, nestedIndex) => ({
        name: feature.node.name,
        value: nestedIndex,
      })),
    })
  }

  if (node.response?.edges) {
    result.children.push({
      name: 'Response',
      value: node.response.edges.length,
      children: node.response.edges.map((response, nestedIndex) => ({
        name: response.node.name,
        value: nestedIndex,
      })),
    })
  }

  if (node.category?.edges) {
    result.children.push({
      name: 'Category',
      value: node.category.edges.length,
      children: node.category.edges.map((category, nestedIndex) => ({
        name: category.node.name,
        value: nestedIndex,
      })),
    })
  }

  if (node.stimulus?.edges) {
    result.children.push({
      name: 'Stimulus',
      value: node.stimulus.edges.length,
      children: node.stimulus.edges.map((stimulus, nestedIndex) => ({
        name: stimulus.node.name,
        value: nestedIndex,
      })),
    })
  }

  if (node.videosubclassSet?.edges) {
    result.children.push(
      ...node.videosubclassSet.edges.map((subclass, subindex) =>
        transformData(subclass.node, subindex),
      ),
    )
  }

  return result
}
export function* LOAD_CLASS_AND_SUBCLASS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const apiResponse = yield call(getVideoClassAndSubClass)

  if (apiResponse && apiResponse.videoClass) {
    const transformedData = apiResponse.videoClass.map((classes, index) =>
      transformData(classes, index),
    )

    yield put({
      type: actions.SET_STATE,
      payload: {
        videoClassAndSubClass: apiResponse.videoClass,
        ClassData: transformedData,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_ANNOTATIONS_COUNTS_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const transformTableData = data => {
    let result = []
    let keyCount = 1
    const totalValues = {
      total: 0,
      RFML: 0,
      blank: 0,
      deleted: 0,
      totalClasses: 0,
      totalSubClassess: 0,
    }

    data.forEach(item => {
      totalValues.total += item.total
      totalValues.RFML += item.rfml
      totalValues.blank += item.blank
      totalValues.deleted += item.delete
      totalValues.totalClasses += 1

      const classObj = {
        key: keyCount.toString(),
        class: item.class_name,
        subClass: '',
        total: item.total,
        RFML: item.rfml,
        blank: item.blank,
        deleted: item.delete,
        children: [],
      }

      keyCount++

      if (item.subclass_data && item.subclass_data.length > 0) {
        item.subclass_data.forEach(subclass => {
          totalValues.totalSubClassess += 1
          const subclassObj = {
            key: keyCount.toString(),
            class: item.class_name,
            subClass: subclass.subclass_name,
            total: subclass.total,
            RFML: subclass.rfml,
            blank: subclass.blank,
            deleted: subclass.delete,
          }

          keyCount++
          classObj.children.push(subclassObj)
        })
      }

      result.push(classObj)
    })

    return { result, totalValues }
  }

  const response = yield call(loadAnnotationsCountsData, payload)
  if (response && response.getClassSubclassCountReport) {
    const { result, totalValues } = transformTableData(
      JSON.parse(response.getClassSubclassCountReport),
    )
    // console.log(response, JSON.parse(response.getClassSubclassCountReport))
    yield put({
      type: actions.SET_STATE,
      payload: {
        ClassCountData: result,
        TotalValues: totalValues,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* ADD_DATA_IN_SUBCLASS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassSaving: true,
    },
  })

  const { videoClassAndSubClass } = yield select(state => state.reports)
  const response = yield call(addDataInSubclass, payload)
  if (response && response.addDataInClass.status) {
    notification.success({
      message: response.addDataInClass.message,
    })
    const allClasses = videoClassAndSubClass.map(item => {
      if (item.id === response.addDataInClass.videoClass.id) {
        return { ...response.addDataInClass.videoClass }
      }
      return item
    })

    if (allClasses) {
      const transformedData = allClasses.map((classes, index) => transformData(classes, index))

      yield put({
        type: actions.SET_STATE,
        payload: {
          videoClassAndSubClass: allClasses,
          ClassData: transformedData,
          isAddSubClassDataDrawer: false,
        },
      })
      yield put({
        type: annotationAction.GET_ANNOTATE_INIT,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSubClassSaving: false,
    },
  })
}

export function* APEND_CLASS_DATA({ payload }) {
  const { subClassData } = payload
  const classId = subClassData.videoclass.id
  const { videoClassAndSubClass } = yield select(state => state.reports)

  if (classId) {
    let classFound = false

    const allClasses = videoClassAndSubClass.map(item => {
      if (item.id === classId) {
        // Check if classId is found
        classFound = true

        // Check if subClassData.id is present in videosubclassSet.edges
        const isSubClassPresent = item.videosubclassSet.edges.some(
          ({ node }) => node.id === subClassData.id,
        )

        if (isSubClassPresent) {
          // If subClassData.id is present, update the existing node
          item.videosubclassSet.edges = item.videosubclassSet.edges.map(({ node }) => {
            if (node.id === subClassData.id) {
              return { node: { ...subClassData } }
            }
            return { node }
          })
        } else {
          // If subClassData.id is not present, append it to the array
          item.videosubclassSet.edges.push({ node: { ...subClassData } })
        }
        return item
      }

      return item
    })

    // If classId is not found, append a new item in the same format
    if (!classFound) {
      const newItem = {
        id: classId,
        name: subClassData.videoclass.name,
        videosubclassSet: {
          edges: [{ node: { ...subClassData } }],
        },
      }

      allClasses.push(newItem)
    }

    if (allClasses) {
      const transformedData = allClasses.map((classes, index) => transformData(classes, index))

      yield put({
        type: actions.SET_STATE,
        payload: {
          videoClassAndSubClass: allClasses,
          ClassData: transformedData,
          isAddSubClassDataDrawer: false,
        },
      })
    }
  }
}

export function* CREATE_CLASS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isClassSaving: true,
    },
  })

  const { videoClassAndSubClass } = yield select(state => state.reports)
  const response = yield call(createClass, payload)
  if (response) {
    notification.success({
      message: 'New Class created successfully',
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        videoClassAndSubClass: [
          ...videoClassAndSubClass,
          { ...response.createVideoClass.videoClass },
        ],
        isAddClassModal: false,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isClassSaving: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_VIDEO_STATU_COUNTS, GET_VIDEO_STATU_COUNTS)])
  yield all([takeLatest(actions.LOAD_STAFF_REPORT_DATA, LOAD_STAFF_REPORT_DATA)])
  yield all([takeLatest(actions.LOAD_CLASSES_DATA, LOAD_CLASSES_DATA)])
  yield all([takeLatest(actions.LOAD_CLASS_AND_SUBCLASS, LOAD_CLASS_AND_SUBCLASS)])
  yield all([takeLatest(actions.LOAD_ANNOTATIONS_COUNTS_DATA, LOAD_ANNOTATIONS_COUNTS_DATA)])
  yield all([takeLatest(actions.ADD_DATA_IN_SUBCLASS, ADD_DATA_IN_SUBCLASS)])
  yield all([takeLatest(actions.APEND_CLASS_DATA, APEND_CLASS_DATA)])
  yield all([takeLatest(actions.CREATE_CLASS, CREATE_CLASS)])
}
