/* eslint-disable no-nested-ternary */
import React from 'react'
import { COLORS } from 'assets/styles/globalStyles'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown, Typography, Button } from 'antd'
import { HiOutlineLogout } from 'react-icons/hi'

const { Title, Text } = Typography

const ProfileMenu = () => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const items = [
    {
      key: 'info',
      label: (
        <>
          <div style={{ display: 'flex', alignItems: 'center', borderBottom: 1 }}>
            <div>
              <Title level={5} style={{ color: COLORS.blackTitle, marginTop: 0 }}>
                Hello,
                {user.role === 'annotation_staff' && user.annotationStaff
                  ? `${user.annotationStaff.firstname} ${user.annotationStaff.lastname || ''} `
                  : 'Frontera'}
              </Title>
              <div
                style={{
                  color: COLORS.blackTitle,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 0,
                }}
              >
                <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                  Role:{' '}
                </Title>
                <Text style={{ marginLeft: 5, marginTop: 0, marginBottom: 0 }}>
                  {user.role === 'annotation_staff' ? 'Annotation Staff' : ''}
                  {user.role === 'superUser' ? 'Admin' : ''}
                </Text>
              </div>
            </div>
          </div>
        </>
      ),
    },
    { type: 'divider' },

    {
      key: 'logout',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <HiOutlineLogout style={{ color: COLORS.primary }} size={20} />
          <Button type="link" style={{ color: COLORS.blackTitle }}>
            <Title level={5} style={{ marginTop: 0 }}>
              Logout
            </Title>
          </Button>
        </div>
      ),
    },
  ]
  const handleMenuClick = e => {
    if (e.key === 'logout') {
      logout()
    }
  }
  const menuProps = {
    items,
    selectable: false,
    onClick: handleMenuClick,
  }
  return (
    <>
      <Dropdown menu={menuProps} trigger="click">
        {/* <div className={styles.dropdown}>
          <HiOutlineLogout style={{ fontSize: 20 }} />
          <div style={{ marginInlineStart: 10 }}> Logout</div>
        </div> */}
        <Button
          type="link"
          style={{
            display: 'flex',
            alignItems: 'center',
            color: COLORS.blackTitle,
            paddingInlineEnd: 0,
          }}
          className="ant-menu-title-content"
        >
          <HiOutlineLogout size={20} style={{ marginRight: 5 }} /> Logout
        </Button>
      </Dropdown>
    </>
  )
}

export default ProfileMenu
