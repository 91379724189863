/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { notification } from 'antd'
import { gql } from '@apollo/client'
import axios from 'axios'
import { REACT_APP_API_URL } from 'apollo/REACT_APP_URL'

import apolloClient, { uploadClient } from '../apollo/config'

export async function getTranscriptList(payload) {
  return apolloClient
    .query({
      query: gql`
        query getTranscriptBySuperadmin(
          $offset: Int
          $name: String
          $first: Int
          $status: AnnotationTranscriptFilesStatusChoices
          $annotator: ID
          $verifier: ID
        ) {
          getTranscriptsBySuperadmin(
            first: $first
            filename_Icontains: $name
            annotator: $annotator
            verifier: $verifier
            status: $status
            offset: $offset
          ) {
            totalCount
            edges {
              node {
                id
                video {
                  id
                  name
                }
                filename
                fileUrl
                isActive
                status
                createdAt
                annotator {
                  id
                  firstname
                  lastname
                }
                verifier {
                  id
                  firstname
                  lastname
                }
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getTranscriptBYAnnotationStaff(payload) {
  return apolloClient
    .query({
      query: gql`
        query getAssignedTranscripts(
          $offset: Int
          $name: String
          $first: Int
          $status: AnnotationTranscriptFilesStatusChoices
          $annotator: ID
          $verifier: ID
          $staffType: String!
        ) {
          getAssignedTranscripts(
            first: $first
            filename_Icontains: $name
            annotator: $annotator
            verifier: $verifier
            status: $status
            offset: $offset
            staffType: $staffType
          ) {
            totalCount
            edges {
              node {
                id
                video {
                  id
                  name
                }
                filename
                fileUrl
                isActive
                status
                createdAt
                annotator {
                  id
                  firstname
                  lastname
                }
                verifier {
                  id
                  firstname
                  lastname
                }
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getUnAssignedTranscriptList(payload) {
  return apolloClient
    .query({
      query: gql`
        query getUnassignedTranscriptsBySuperadmin(
          $offset: Int
          $name: String
          $first: Int
          $staffType: String!
          $status: AnnotationTranscriptFilesStatusChoices
        ) {
          getUnassignedTranscriptsBySuperadmin(
            staffType: $staffType
            first: $first
            filename_Icontains: $name
            status: $status
            offset: $offset
          ) {
            totalCount
            edges {
              node {
                id
                video {
                  id
                  name
                }
                filename
                fileUrl
                status
                isActive
                createdAt
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function uploadVideoTranscript(payload) {
  const token = JSON.parse(localStorage.getItem('token'))
  const formData = new FormData()
  formData.append(
    'operations',
    JSON.stringify({
      query: `
      mutation uploadTranscirptFile($file: Upload!, $videoId: ID!) {
        uploadTranscirptFile(input: { file: $file, videoId: $videoId }) {
          message
          status
          transcriptFile {
            id
            video {
              id
              name
            }
            filename
            fileUrl
            status
            isActive
            annotator{
              id
              firstname
              lastname
            }
            verifier{
              id
              firstname
              lastname
            }
          }
        }
      }
      `,
      variables: {
        file: null,
        videoId: payload.videoId,
      },
    }),
  )
  formData.append(
    'map',
    JSON.stringify({
      '0': ['variables.file'],
    }),
  )
  formData.append('0', payload.file)

  return axios({
    method: 'post',
    url: `${REACT_APP_API_URL}/graphql`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token ? `JWT ${token}` : '',
    },
  })
    .then(result => result.data.data)
    .catch(error => console.log(error))
}

export async function getVideosForTranscript(payload) {
  return apolloClient
    .query({
      query: gql`
        query getVideos($first: Int, $offset: Int, $name: String) {
          getVideos(offset: $offset, first: $first, name_Icontains: $name) {
            totalCount
            edges {
              node {
                id
                name
                status
                transcriptfilesSet {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotationStaff() {
  return apolloClient
    .query({
      query: gql`
        query {
          getAnnotationStaff {
            edges {
              node {
                id
                firstname
                lastname
                email
                isAnnotator
                isVerifier
                user {
                  id
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function assignTranscriptToStaff(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignTranscriptToAnnotator(
          $annotationStaff: ID!
          $transcriptFiles: [ID]
          $staffType: String!
        ) {
          assignTranscriptToAnnotator(
            input: {
              annotationStaff: $annotationStaff
              staffType: $staffType
              transcriptFiles: $transcriptFiles
            }
          ) {
            message
            status
            transcriptFiles {
              id
              filename
              annotator {
                id
              }
              annotatorAssignedOn
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getTranscriptData(payload) {
  return apolloClient
    .query({
      query: gql`
        query getTranscriptDataByFileId($first: Int, $offset: Int, $transcriptId: ID!) {
          getTranscriptDataByFileId(first: $first, offset: $offset, transFileId: $transcriptId) {
            totalCount
            edges {
              node {
                id
                lineNo
                conversation
                fromTime
                toTime
                speaker
              }
            }
          }
        }
      `,
      variables: { ...payload },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function updateTranscript(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateTranscriptSpeaker($speaker: String, $transcriptDataId: ID!) {
          updateTranscriptSpeaker(
            input: { transcriptDataId: $transcriptDataId, speaker: $speaker }
          ) {
            message
            status
            transcriptData {
              id
              transcriptFile {
                filename
                fileUrl
                isActive
              }
              lineNo
              conversation
              fromTime
              toTime
              speaker
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function addBlock(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation addBlock(
          $clinicalFeedback: String
          $responseReason: String
          $response: String!
          $transcriptDataIds: [ID]!
        ) {
          addBlockOnTranscriptData(
            input: {
              transcriptDataIds: $transcriptDataIds
              response: $response
              responseReason: $responseReason
              clinicalFeedback: $clinicalFeedback
            }
          ) {
            message
            status
            transcriptData {
              id
              transcriptFile {
                filename
                fileUrl
                isActive
              }
              lineNo
              conversation
              fromTime
              toTime
              speaker
              videoClass {
                id
                name
              }
              videoSubclass {
                id
                name
              }
              annotationBlock {
                id
                response
                reason
                clinicalIntelligenceInput
                annotatedBy {
                  id
                  firstName
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function updateBlock(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateBlock(
          $clinicalFeedback: String
          $responseReason: String
          $response: String!
          $blockId: ID!
          $transcriptDataIds: [ID]!
        ) {
          updateBlockOnTranscriptData(
            input: {
              transcriptDataIds: $transcriptDataIds
              blockId: $blockId
              response: $response
              responseReason: $responseReason
              clinicalFeedback: $clinicalFeedback
            }
          ) {
            message
            status
            transcriptData {
              id
              transcriptFile {
                filename
                fileUrl
                isActive
              }
              lineNo
              conversation
              fromTime
              toTime
              speaker
              videoClass {
                id
                name
              }
              videoSubclass {
                id
                name
              }
              annotationBlock {
                id
                response
                reason
                clinicalIntelligenceInput
                annotatedBy {
                  id
                  firstName
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
