import { all } from 'redux-saga/effects'

import user from './user/sagas'
import menu from './menu/sagas'
import annotation from './Annotation/sagas'
import annotationClips from './AnnotationClips/sagas'
import settings from './settings/sagas'
import annotationPDF from './annotationPDF/sagas'
import annotationLearner from './AnnotationLearners/sagas'
import annotationStaff from './AnnotationStaffs/sagas'
import annotationForStaff from './AnnotationForStaff/sagas'
import annotationVideos from './AnnotationVideos/sagas'
import reports from './Reports/sagas'
import transcripts from './Transcripts/sagas'
import targets from './Targets/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    annotation(),
    settings(),
    annotationPDF(),
    annotationLearner(),
    annotationStaff(),
    annotationForStaff(),
    annotationClips(),
    annotationVideos(),
    reports(),
    transcripts(),
    targets(),
  ])
}
