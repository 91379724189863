const ANNOTATION_FOR_STAFF = {
  SET_STATE: 'annotationForStaff/SET_STATE',
  GET_ASSIGNED_VIDEOS: 'annotationForStaff/GET_ASSIGNED_VIDEOS',
  GET_VIDEO_BY_ID: 'annotationForStaff/GET_VIDEO_BY_ID',
  GET_VIDEO_CLIPS_BY_CLASS: 'annotationForStaff/GET_VIDEO_CLIPS_BY_CLASS',
  GET_VIDEO_CLASS: 'annotationForStaff/GET_VIDEO_CLASS',
  GET_ANNOTATE_INIT: 'annotationForStaff/GET_ANNOTATE_INIT',
  CREATE_UPDATE_ANNOTATION_BY_ANNOTATOR: 'annotationForStaff/CREATE_ANNOTATION_BY_ANNOTATOR',
  APPEND_ANNOTATOR_ANNOTATION: 'annotationForStaff/APPEND_ANNOTATOR_ANNOTATION',
  POP_ANNOTATOR_ANNOTATION: 'annotationForStaff/POP_ANNOTATOR_ANNOTATION',

  DELETE_ANNOTATION: 'annotationForStaff/DELETE_ANNOTATION',
  CREATE_UPDATE_ANNOTATION_BY_VERIFIER: 'annotationForStaff/CREATE_ANNOTATION_BY_VERIFIER',
  APPEND_VERIFIER_ANNOTATION: 'annotationForStaff/APPEND_VERIFIER_ANNOTATION',
  POP_VERIFIER_ANNOTATION: 'annotationForStaff/POP_VERIFIER_ANNOTATION',

  SUBMIT_VIDEO_ANNOTATION_BY_ANNOTATOR: 'annotationForStaff/SUBMIT_VIDEO_ANNOTATION_BY_ANNOTATOR',
  SUBMIT_VIDEO_ANNOTATION_BY_VERIFIER: 'annotationForStaff/SUBMIT_VIDEO_ANNOTATION_BY_VERIFIER',

  GET_PREVIEW_ANVIL_DATA: 'annotationForStaff/GET_PREVIEW_ANVIL_DATA',
  GENERATE_ANVIL_BACKGROUND: 'annotationForStaff/GENERATE_ANVIL_BACKGROUND',
  REPLACE_ANNOTATOR_STATUS: 'annotationForStaff/REPLACE_ANNOTATOR_STATUS',
  REPLACE_VERIFIER_STATUS: 'annotationForStaff/REPLACE_VERIFIER_STATUS',
}

export default ANNOTATION_FOR_STAFF
