const actions = {
  SET_STATE: 'targets/SET_STATE',
  GET_LEARNERS: 'targets/GET_LEARNERS',
  GET_TARGETS: 'targets/GET_TARGETS',
  ADD_OR_EDIT_TARGET: 'targets/ADD_OR_EDIT_TARGET',
  APPEND_TARGET: 'targets/APPEND_TARGET',
  UPDATE_TARGET: 'targets/UPDATE_TARGET',
  GET_TARGETS_TEMPLATE: 'targets/GET_TARGETS_TEMPLATE',
}

export default actions
