import ANNOTATION_VIDEOS from './actions'

const initialState = {
  annotationStaffs: [],
  isStaffsLoading: false,
  selectedStaff: null,

  isAssignVideoDrawer: false,
  isUnAssignedVideosLoading: false,
  unAssignedVideos: [],
  unAssignedVideosCount: 0,
  unAssignedVideosPageInfo: null,
  unAssignedVideosType: 'next',
  unAssignedVideosPrevPageNo: 1,

  isAssignStaffModal: false,
  isStaffAssigning: false,
  selectedVideos: [],
  selectedStaffType: 'annotator',

  // pagination
  staffCount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ANNOTATION_VIDEOS.SET_STATE:
      return { ...state, ...action.payload }
    case ANNOTATION_VIDEOS.REMOVE_ASSIGNED_VIDEOS: {
      const removedVideoIds = action.payload.data.map(node => node.id)
      const updatedUnassignedVideos = state.unAssignedVideos.filter(
        ({ node: video }) => !removedVideoIds.includes(video.id),
      )
      return {
        ...state,
        selectedVideos: [],
        selectedStaff: null,
        isAssignStaffModal: false,
        unAssignedVideos: updatedUnassignedVideos,
      }
    }

    case ANNOTATION_VIDEOS.APPEND_ASSIGNED_VERIFY_VIDEO:
      return {
        ...state,
        isAssignVerifyModal: false,
        allAssignedVideosAsVerifier: [
          ...state.allAssignedVideosAsVerifier,
          ...action.payload.data.map(item => ({ node: item })),
        ],
      }
    default:
      return state
  }
}
