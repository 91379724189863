/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function createPDFAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation AddPdfForAnnotation($studentId: ID!, $url: String!, $name: String) {
          addPdfForAnnotation(input: { url: $url, name: $name, learner: $studentId }) {
            message
            status
            learnerPdf {
              id
              url
              student {
                id
                name
              }
              isActive
              name
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotationPDFs() {
  return apolloClient
    .query({
      query: gql`
        query {
          getPdfForAnnotations {
            edges {
              node {
                id
                url
                student {
                  id
                  name
                }
                isActive
                name
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getAnnotationPDFById(payload) {
  return apolloClient
    .query({
      query: gql`
        query($pdfId: ID!) {
          getPdfForAnnotationsById(id: $pdfId) {
            id
            url
            name
            student {
              id
              name
            }
            isActive
            pdfannotationSet {
              edges {
                node {
                  id
                  pageNo
                  selectedText
                  selectedPixel
                  comment
                  level1Value
                  level2Value
                  level3Value
                  level4Value
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function addPDFAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createPdfAnnotation(
          $pdfId: ID!
          $selectedText: String
          $comment: String
          $highlight: JSONString
          $pageNo: Int!
          $level1: String
          $level2: String
          $level3: String
          $level4: String
        ) {
          createPdfAnnotation(
            input: {
              learnerPdfId: $pdfId
              pageNo: $pageNo
              selectedText: $selectedText
              selectedPixel: $highlight
              comment: $comment
              level1: $level1
              level2: $level2
              level3: $level3
              level4: $level4
            }
          ) {
            message
            status
            pdfAnnotation {
              id
              learnerPdf {
                id
                name
              }
              pageNo
              selectedText
              selectedPixel
              comment
              level1Value
              level2Value
              level3Value
              level4Value
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function editPDFAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updatePdfAnnotation(
          $id: ID!
          $comment: String
          $highlight: JSONString
          $level1: String
          $level2: String
          $level3: String
          $level4: String
        ) {
          updatePdfAnnotation(
            input: {
              pk: $id
              comment: $comment
              selectedPixel: $highlight
              level1: $level1
              level2: $level2
              level3: $level3
              level4: $level4
            }
          ) {
            message
            status
            pdfAnnotation {
              id
              learnerPdf {
                id
                name
              }
              pageNo
              comment
              selectedText
              selectedPixel
              level1Value
              level2Value
              level3Value
              level4Value
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function deletePDFAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deletePdfAnnotation($id: ID!) {
          deletePdfAnnotation(input: { pk: $id }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function deleteAllPDFAnnotation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteAllAnnotionsOfPdf($id: ID!) {
          deleteAllAnnotionsOfPdf(input: { pk: $id }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getPdfAnnotationLevel1() {
  return apolloClient
    .query({
      query: gql`
        query {
          getPdfAnnotaionLevel1 {
            edges {
              node {
                id
                name
                pdfannotationlevel2Set {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                pdfannotationlevel3Set {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                pdfannotationlevel4Set {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
