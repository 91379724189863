import React, { Fragment, useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

const IndexLayout = props => {
  let previousPath = ''
  const { location, prevLocation, children } = props
  const { pathname, search } = location
  const user = useSelector(state => state.user)

  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }, [location, prevLocation])

  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    NProgress.start()
  }

  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }

    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return 'login'
    }

    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isLoginLayout = getLayout() === 'login'
  const isPublic = getLayout() === 'public'
  const userRole = user.role
  const isAnnotationStaff = user.annotationStaff

  const BootstrappedLayout = () => {
    // Autism Screening
    if (isPublic) {
      return <Container>{children}</Container>
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
      return <Loader />
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !isUserAuthorized) {
      return <Redirect to="/user/login" />
    }

    // if Super User
    if (isLoginLayout && isUserAuthorized && userRole === 'superUser') {
      // return <Redirect to="/dashboard/clinic_admin" />
      return <Redirect to="/annotationdashboard" />
    }
    // if annotation staff
    if (
      isLoginLayout &&
      isUserAuthorized &&
      userRole === 'annotation_staff' &&
      isAnnotationStaff &&
      isAnnotationStaff.isAnnotator
    ) {
      // return <Redirect to="/dashboard/clinic_admin" />
      return <Redirect to="/annotationStaff/annotation" />
    }

    if (
      isLoginLayout &&
      isUserAuthorized &&
      userRole === 'annotation_staff' &&
      isAnnotationStaff &&
      isAnnotationStaff.isVerifier
    ) {
      // return <Redirect to="/dashboard/clinic_admin" />
      return <Redirect to="/annotation/annotated-clips" />
    }

    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Annotation | %s" title="Annotation Application" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(IndexLayout)
