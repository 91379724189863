/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function login(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation TokenAuth($username: String!, $password: String!) {
          tokenAuth(input: { username: $username, password: $password }) {
            token
            # firstLogin
            user {
              id
              firstName
              lastName
              email
              username
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              annotationstaff {
                id
                firstname
                lastname
                email
                isVerifier
                isAnnotator
                user {
                  id
                }
              }
            }
          }
        }
      `,
      variables: {
        username: payload.username.trim(),
        password: payload.password,
      },
    })
    .then(data => {
      // console.log(data, 'user data')
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'login',
        userId: data?.data.tokenAuth.user.id,
      })
      localStorage.setItem('token', JSON.stringify(data?.data.tokenAuth.token))
      if (data?.data.tokenAuth.user) {
        localStorage.setItem('userName', JSON.stringify(data?.data.tokenAuth.user.firstName))
      }
      if (data?.data.tokenAuth.user.groups.edges.length > 0) {
        localStorage.setItem(
          'role',
          JSON.stringify(data?.data.tokenAuth.user.groups.edges[0]?.node.name),
        )
      }
      localStorage.setItem('database', JSON.stringify('india'))
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function StudentIdFromUserId(payload) {
  return apolloClient
    .query({
      query: gql`{
          students (parent:"${payload}")  {
            edges {
              node {
                id, parentName,
              }
            }
          }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item =>
        notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        }),
      )
    })
}

export async function clinicDetails() {
  return apolloClient
    .query({
      query: gql`
        {
          schoolDetail {
            id
            schoolName
            address
            email
            mlSimilarity
            mlGraph
            mlRecommendation
            country {
              id
              name
            }
          }
        }
      `,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item =>
        notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        }),
      )
    })
}

export async function StaffIdFromUserId(payload) {
  return apolloClient
    .query({
      query: gql`{
          staffs (user:"${payload}")  {
            edges {
              node {
                id
                name
                surname
                email
                state
                country
                designation
                position {
                  id
                  title
                }
                school {
                  id
                  schoolName
                  user{
                    id
                  }
                }
                credentials {
                  id
                  name
                  goals
                  shortGoal
                  targetAllocation
                  curriculum
                }
              }
            }
          }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item =>
        notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        }),
      )
    })
}

export async function GetUserDetailsByUsername(payload) {
  return apolloClient
    .query({
      query: gql`{
        getuser(username:"${payload}") {
          edges {
            node {
              id
              username              
              firstName
              lastName
              email
              annotationstaff {
                      id
                      firstname
                      lastname
                      email
                      isVerifier
                      isAnnotator
                      user {
                        id
                      }
                    }
                    groups {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item =>
        notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        }),
      )
    })
}

export async function RefreshToken() {
  if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
    return false
  }

  const query = `mutation RefreshToken($token: String!) {
        refreshToken(input: {token: $token}) {
          token
          payload
        }
      }`
  const variables = {
    token: JSON.parse(localStorage.getItem('token')),
  }

  return apolloClient
    .mutate({
      mutation: gql`
        mutation RefreshToken($token: String!) {
          refreshToken(input: { token: $token }) {
            token
            payload
          }
        }
      `,
      variables: {
        token: JSON.parse(localStorage.getItem('token')),
      },
    })
    .then(data => {
      localStorage.setItem('database', JSON.stringify('india'))
      localStorage.setItem('token', JSON.stringify(data?.data.refreshToken.token))
      return data?.data
    })
    .catch(err => err)
}

export async function logout() {
  return apolloClient.cache.reset()
}

export async function GetStudentNameById(payload) {
  return apolloClient
    .query({
      query: gql`{
        student(id: ${payload}) {
          firstname
        }
      }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item =>
        notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        }),
      )
    })
}
