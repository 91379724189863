import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
// Annotation reducer
import annotationReducer from './Annotation/reducers'
import annotationClips from './AnnotationClips/reducers'

import annotationPDFReducer from './annotationPDF/reducers'
import annotationLearnerReducer from './AnnotationLearners/reducers'
import annotationStaff from './AnnotationStaffs/reducers'
import annotationForStaff from './AnnotationForStaff/reducers'
import annotationVideos from './AnnotationVideos/reducers'
import reportReducer from './Reports/reducers'
import transcriptsReducer from './Transcripts/reducers'
import targetsReducer from './Targets/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    annotation: annotationReducer,
    settings,
    annotationPDF: annotationPDFReducer,
    annotationLearner: annotationLearnerReducer,
    annotationStaff,
    annotationForStaff,
    annotationClips,
    annotationVideos,
    reports: reportReducer,
    transcripts: transcriptsReducer,
    targets: targetsReducer,
  })
