import actions from './actions'

const initialState = {
  loading: false,
  isLearnerLoading: false,
  learners: [],
  selectedLearner: null,
  learnerPageInfo: null,

  isTargetsLoading: false,
  targets: [],
  selectedTarget: null,
  isTargetSaving: false,
  addTargetDrawer: false,

  isTemplateLoading: false,
  TargetTemplate: null,
}

export default function targetsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.APPEND_TARGET: {
      return {
        ...state,
        addTargetDrawer: false,
        targets: [
          {
            node: action.payload.target,
          },
          ...state.targets,
        ],
      }
    }
    case actions.UPDATE_TARGET: {
      return {
        ...state,
        selectedTarget: action.payload.target,
        addTargetDrawer: false,
        targets: [
          ...state.targets.map(({ node: item }) => {
            if (item.id === action.payload.target.id) {
              return { node: { ...item, ...action.payload.target } }
            }
            return { node: item }
          }),
        ],
      }
    }
    default:
      return state
  }
}
