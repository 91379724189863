const actions = {
  SET_STATE: 'reports/SET_STATE',
  GET_VIDEO_STATU_COUNTS: 'reports/GET_VIDEO_STATU_COUNTS',
  // Staff reports actions
  LOAD_STAFF_REPORT_DATA: 'reports/LOAD_STAFF_REPORT_DATA',
  // Classes report actions
  LOAD_CLASSES_DATA: 'reports/LOAD_CLASSES_DATA',

  LOAD_CLASS_AND_SUBCLASS: 'reports/LOAD_CLASS_AND_SUBCLASS',

  LOAD_ANNOTATIONS_COUNTS_DATA: 'reports/LOAD_ANNOTATIONS_COUNTS_DATA',
  ADD_DATA_IN_SUBCLASS: 'reports/ADD_DATA_IN_SUBCLASS',
  APEND_CLASS_DATA: 'reports/APPEND_CLASS_DATA',
  CREATE_CLASS: 'reports/CREATE_CLASS',
}

export default actions
