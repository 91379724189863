/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, put, takeEvery, call, select, takeLatest } from 'redux-saga/effects'
import {
  createPDFAnnotation,
  getAnnotationPDFs,
  getAnnotationPDFById,
  addPDFAnnotation,
  editPDFAnnotation,
  deletePDFAnnotation,
  deleteAllPDFAnnotation,
  getPdfAnnotationLevel1,
} from 'services/annotationPDF'
import actions from './actions'

export function* GET_ANNOTATED_PDF() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(getAnnotationPDFs)
    if (response) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          annotationPDFs: response.getPdfForAnnotations.edges,
        },
      })
    }
  } catch (error) {
    console.log('get pdfs for annotation error ==> ', JSON.stringify(error))
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_PDF_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isPDFSaving: true,
    },
  })
  try {
    const response = yield call(createPDFAnnotation, payload)
    if (response && response.addPdfForAnnotation.status) {
      notification.success({
        message: 'PDF added successfully',
      })
      const { annotationPDFs } = yield select(state => state.annotationPDF)
      yield put({
        type: actions.SET_STATE,
        payload: {
          isAddPDFDrawer: false,
          annotationPDFs: [{ node: response.addPdfForAnnotation.learnerPdf }, ...annotationPDFs],
        },
      })
    }
  } catch (error) {
    console.log('create pdf annotation error ', JSON.stringify(error))
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isPDFSaving: false,
    },
  })
}

export function* GET_PDF_BY_ID({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSelectedPdfLoading: true,
    },
  })

  try {
    const response = yield call(getAnnotationPDFById, payload)
    const highlightsObject = []
    if (response && response.getPdfForAnnotationsById) {
      const { edges } = response.getPdfForAnnotationsById.pdfannotationSet
      if (edges.length > 0) {
        for (let i = 0; i < edges.length; i++) {
          const object = edges[i].node.selectedPixel
          const jsonObject = JSON.parse(object)
          if (jsonObject) {
            highlightsObject.push({
              ...jsonObject,
              ...edges[i].node,
              id: edges[i].node.id,
              comment: edges[i].node.comment,
            })
          }
        }
      }

      yield put({
        type: actions.SET_STATE,
        payload: {
          selectedPDF: response.getPdfForAnnotationsById,
          highlights: highlightsObject,
        },
      })
    }
  } catch (error) {
    console.log('get pdf by id for annotation error ==> ', JSON.stringify(error))
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isSelectedPdfLoading: false,
    },
  })
}

export function* ADD_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      addAnnotationLoading: true,
    },
  })
  try {
    const { selectedPDF, highlights } = yield select(state => state.annotationPDF)
    const data = {
      ...payload,
      pdfId: selectedPDF.id,
      selectedText: payload.highlight.content?.text ? payload.highlight.content?.text : '',
      highlight: JSON.stringify(payload.highlight),
      pageNo: payload.highlight.position.pageNumber,
    }

    const response = yield call(addPDFAnnotation, data)
    if (response && response.createPdfAnnotation.status) {
      notification.success({
        message: 'Highlight added successfully',
      })

      // ADD THE HIGHLIGHT IN THE HIGHLIGHTS REDUX OBJECT
      yield put({
        type: actions.SET_STATE,
        payload: {
          highlights: [
            {
              id: response.createPdfAnnotation.pdfAnnotation.id,
              ...payload.highlight,
              ...response.createPdfAnnotation.pdfAnnotation,
            },
            ...highlights,
          ],
        },
      })
    }
  } catch (error) {
    console.log('add annotation error ==> ', JSON.stringify(error))
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      addAnnotationLoading: false,
    },
  })
}

export function* EDIT_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      editAnnotationLoadinng: true,
    },
  })
  try {
    const { highlights } = yield select(state => state.annotationPDF)
    const data = {
      ...payload,
      highlight: JSON.stringify(payload.highlight),
    }
    const response = yield call(editPDFAnnotation, data)
    if (response && response.updatePdfAnnotation.status) {
      notification.success({
        message: 'Highlight Edited successfully',
      })
      // REPLACE THE HIGHLIGHT IN THE HIGHLIGHTS REDUX OBJECT
      const newData = highlights.map(item => {
        if (item.id === payload.id) {
          return { ...item, ...response.updatePdfAnnotation.pdfAnnotation }
        }
        return item
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          highlights: newData,
          selectedHighlight: null,
        },
      })
    }
  } catch (error) {
    console.log('edit annotation error ==> ', JSON.stringify(error))
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      editAnnotationLoadinng: false,
    },
  })
}

export function* DELETE_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      editAnnotationLoadinng: true,
    },
  })
  try {
    const { highlights } = yield select(state => state.annotationPDF)
    const response = yield call(deletePDFAnnotation, payload)
    if (response && response.deletePdfAnnotation.status) {
      notification.success({
        message: 'Highlight Deleted successfully',
      })
      // DELETE THE HIGHLIGHT IN THE HIGHLIGHTS REDUX OBJECT
      const newData = highlights.filter(item => item.id !== payload.id)

      yield put({
        type: actions.SET_STATE,
        payload: {
          highlights: newData,
        },
      })
    }
  } catch (error) {
    console.log('delete annotation error ==> ', JSON.stringify(error))
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      editAnnotationLoadinng: false,
    },
  })
}

export function* DELETE_ALL_ANNOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      editAnnotationLoadinng: true,
    },
  })

  try {
    const response = yield call(deleteAllPDFAnnotation, payload)
    if (response && response.deleteAllAnnotionsOfPdf.status) {
      notification.success({
        message: 'All Highlights Deleted successfully',
      })
      // DELETE ALL THE HIGHLIGHT IN THE HIGHLIGHTS REDUX OBJECT
      yield put({
        type: actions.SET_STATE,
        payload: {
          highlights: [],
        },
      })
    }
  } catch (error) {
    console.log('delete annotation error ==> ', JSON.stringify(error))
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      editAnnotationLoadinng: false,
    },
  })
}

export function* GET_PDF_ANNOTATION_LEVEL1({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationLevel1Loading: true,
    },
  })

  const response = yield call(getPdfAnnotationLevel1, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        annotationLevel1: response.getPdfAnnotaionLevel1.edges,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isAnnotationLevel1Loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_ANNOTATED_PDF, GET_ANNOTATED_PDF),
    takeLatest(actions.CREATE_PDF_ANNOTATION, CREATE_PDF_ANNOTATION),
    takeLatest(actions.GET_PDF_BY_ID, GET_PDF_BY_ID),
    takeEvery(actions.ADD_ANNOTATION, ADD_ANNOTATION),
    takeEvery(actions.EDIT_ANNOTATION, EDIT_ANNOTATION),
    takeEvery(actions.DELETE_ANNOTATION, DELETE_ANNOTATION),
    takeEvery(actions.DELETE_ALL_ANNOTATION, DELETE_ALL_ANNOTATION),
    takeEvery(actions.GET_PDF_ANNOTATION_LEVEL1, GET_PDF_ANNOTATION_LEVEL1),
  ])
}
