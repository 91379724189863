import ANNOTATION_STAFFS from './actions'

const initialState = {
  annotationStaffs: [],
  isStaffsLoading: false,
  isStaffSaving: false,
  selectedStaff: null,
  isCreateStaffDrawer: false,

  isAssignVideoDrawer: false,
  isUnAssignedVideosLoading: false,
  unAssignedVideos: [],

  isAnnotatorVideosLoading: false,
  allAssignedVideosAsAnnotator: [],
  isAssignAnnotateModal: false,
  isAnnotatorVideoSaving: false,

  isVerifierVideoLoading: false,
  allAssignedVideosAsVerifier: [],
  isAssignVerifyModal: false,
  isVerifyVideoSaving: false,

  // pagination
  staffCount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ANNOTATION_STAFFS.SET_STATE:
      return { ...state, ...action.payload }
    case ANNOTATION_STAFFS.APPEND_ANNOTATION_STAFF:
      return {
        ...state,
        isCreateStaffDrawer: false,
        annotationStaffs: [...state.annotationStaffs, { node: action.payload.data }],
      }
    case ANNOTATION_STAFFS.REPLACE_ANNOTATION_STAFF: {
      const updatedUsers = state.annotationStaffs.map(({ node }) => {
        if (node.id === action.payload.data.id) {
          return { node: { ...action.payload.data } }
        }
        return { node }
      })
      return {
        ...state,
        annotationStaffs: updatedUsers,
        selectedStaff: null,
        isCreateStaffDrawer: false,
      }
    }
    case ANNOTATION_STAFFS.APPEND_ASSIGNED_ANNOTATE_VIDEO:
      return {
        ...state,
        isAssignAnnotateModal: false,
        allAssignedVideosAsAnnotator: [
          ...state.allAssignedVideosAsAnnotator,
          ...action.payload.data.map(item => ({ node: item })),
        ],
      }
    case ANNOTATION_STAFFS.APPEND_ASSIGNED_VERIFY_VIDEO:
      return {
        ...state,
        isAssignVerifyModal: false,
        allAssignedVideosAsVerifier: [
          ...state.allAssignedVideosAsVerifier,
          ...action.payload.data.map(item => ({ node: item })),
        ],
      }
    default:
      return state
  }
}
